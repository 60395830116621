<template lang="pug">
.bid-require
  dialogMask(:show.sync="showDialog", :showClose="true")
    .title
      img(src="../../../../assets/yaoqiu@2x.png" alt="")
      p 投标要求
    .content
      ul
        li
          .options
            .name.little 案例真实性
            .item.little
              el-switch.switch(
                v-model="form.demandRealCase"
              )
              | {{ form.demandRealCase ? '要求自有案例真实，并支持核验' : '暂无要求' }}
        li
          .options
            .name.little 提供源码
            .item.little
              el-switch.switch(
                v-model="form.demandSourceCode"
              )
              | {{ form.demandSourceCode ? '提供完整源文件，包括原型图、视觉设计、技术方案和源代码' : '暂无要求' }}
          .options(v-if="form.demandSourceCode")
            .name.little 代码解说
            .item.little
              el-switch.switch(
                v-model="form.demandCodeExplication"
              )
              | {{ form.demandCodeExplication ? '配备代码、数据库等录屏解说视频' : '暂无要求'}}
        li
          .options
            .name 提供样稿
            .item
              el-select.half(v-model="demandDraft" placeholder="请选择")
                el-option(label="无需提供" value="无需提供")
                el-option(label="可选提供" value="可选提供")
                el-option(label="必须提供" value="必须提供")
          .options(v-if="demandDraft !== '无需提供'")
            .name 样稿页面
            .item
              el-input.half.right(v-model="form.draftPageName" placeholder="请输入内容" require)
          .options(v-if="demandDraft !== '无需提供'")
            .name 样稿范围
            .item
              .radio(:class="{select: form.demandProductDraft}", @click="select('demandProductDraft')") 产品
              .radio(:class="{select: form.demandUiDraft}", @click="select('demandUiDraft')") 视觉
              //- el-radio-group(v-model="form.demandProductDraft")
              //-   el-radio-button.radio(:label="true") 
              //-   el-radio-button.(:label="false") 
          .options(v-if="demandDraft !== '无需提供'")
            .name 每稿报酬
            .item
              el-select.half(v-model="form.paymentPerDraft" placeholder="请选择")
                el-option(label="50元" value="50")
                el-option(label="100元" value="100")
                el-option(label="150元" value="150")
                el-option(label="200元" value="200")
                el-option(label="250元" value="250")
                el-option(label="300元" value="300")
          .options
            .name 其他要求
            .item
              el-input.textarea(
                type="textarea"
                placeholder="请输入内容"
                v-model="form.demandOthers"
              )     
        
    .submit(@click="submit") 设置
</template>

<script>
import dialogMask from '@/components/common/dialog.vue'
import {setTenderConfig} from '@/api/project'
export default {
  name: 'publishDialog',
  props: ['projectId', 'showSetDialog', 'info'],
  data() {
    return {
      showDialog: false,
      demandDraft: '无需提供',
      form: {
        demandCodeExplication: false,
        demandDraft: false,
        paymentPerDraft: '',
        demandOthers: '',
        draftPageName: '',
        demandRealCase: false,
        demandUiDraft: false,
        demandProductDraft: false,
        demandSourceCode: false,
        demandDraftNecessary: false,
        projectId: ''
      }
    }
  },
  watch: {
    showSetDialog(val) {
      if (val) this.showDialog = true
    },
    showDialog(val) {
      if (!val) {
        this.$emit('update:showSetDialog', false)
        this.reset()
      }
    },
    demandDraft(val) {
      if (val === '无需提供') {
        this.form.demandDraftNecessary = false
        this.form.demandDraft = false
      } else if (val === '可选提供') {
        this.form.demandDraftNecessary = false
        this.form.demandProductDraft = true
        this.form.demandDraft = true
      } else {
        this.form.demandProductDraft = true
        this.form.demandDraftNecessary = true
        this.form.demandDraft = true
      }
    },
    info(val) {
      this.form = {
        demandCodeExplication: val.demandCodeExplication,
        demandDraft: val.demandDraft,
        paymentPerDraft: val.paymentPerDraft,
        demandOthers: val.demandOthers,
        draftPageName: val.draftPageName,
        demandRealCase: val.demandRealCase,
        demandUiDraft: val.demandUiDraft,
        demandProductDraft: val.demandProductDraft,
        demandSourceCode: val.demandSourceCode,
        demandDraftNecessary: val.demandDraftNecessary,
        projectId: ''
      }
      if (this.form.demandProductDraft && this.form.demandDraftNecessary && this.form.demandDraft) {
        this.demandDraft = '必须提供'
      }
      if (this.form.demandProductDraft && !this.form.demandDraftNecessary && this.form.demandDraft) {
        this.demandDraft = '可选提供'
      }
      if (this.form.demandDraftNecessary && !this.form.demandDraft) {
        this.demandDraft = '无需提供'
      }
    }
  },
  components: {
    dialogMask
  },
  methods: {
    reset() {
      this.form = {
        demandCodeExplication: this.info.demandCodeExplication,
        demandDraft: this.info.demandDraft,
        paymentPerDraft: this.info.paymentPerDraft,
        demandOthers: this.info.demandOthers,
        draftPageName: this.info.draftPageName,
        demandRealCase: this.info.demandRealCase,
        demandUiDraft: this.info.demandUiDraft,
        demandProductDraft: this.info.demandProductDraft,
        demandSourceCode: this.info.demandSourceCode,
        demandDraftNecessary: this.info.demandDraftNecessary,
        projectId: ''
      }
      if (this.form.demandProductDraft && this.form.demandDraftNecessary && this.form.demandDraft) {
        this.demandDraft = '必须提供'
      }
      if (this.form.demandProductDraft && !this.form.demandDraftNecessary && this.form.demandDraft) {
        this.demandDraft = '可选提供'
      }
      if (this.form.demandDraftNecessary && !this.form.demandDraft) {
        this.demandDraft = '无需提供'
      }
    },
    select(type) {
      if (type === 'demandProductDraft') {
        if (this.form.demandUiDraft) {
          this.$set(this.form, 'demandProductDraft', !this.form.demandProductDraft)
        }
      } else if (type === 'demandUiDraft') {
        if (this.form.demandProductDraft) {
          this.$set(this.form, 'demandUiDraft', !this.form.demandUiDraft)
        }
      }
    },
    verify() {
      let message = ''
      if (this.form.demandOthers.length > 200) {
        message = '其它备注限200字'
      }
      if (this.demandDraft === '必须提供' || this.demandDraft === '可选提供') {
        if (this.form.draftPageName.length > 20) {
          message = '样稿页面名称不能超过20个字'
        }
        if (this.form.draftPageName === '') {
          message = '请输入样稿页面'
        }
        if (!this.form.paymentPerDraft) {
          message = '请选择样稿报酬'
        }
      }
      if (message) {
        this.$message.error(message)
        return false
      }
      return true
    },
    submit() {
      if (!this.verify()) return
      setTenderConfig(
        Object.assign(this.form, {
          projectId: this.projectId
        })
      ).then(res => {
        if (res.code === 200) {
          this.$emit('uploadSuccess')
          this.$message.success('设置成功')
          this.showDialog = false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.bid-require {
  user-select: none;
  .title {
    padding-left: 20px;
    margin-bottom: 60px;
    img {
      width: 31px;
      height: 30px;
      margin-top: 2px;
      margin-right: 10px;
      display: inline-block;
      font-size: 0;
    }
    p {
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 33px;
      display: inline-block;
      vertical-align: top;
    }
  }
  li {
    padding-bottom: 20px;
    margin-bottom: 40px;
    border-bottom: 1px solid #f1f1f1;
    &:last-child {
      border-bottom: 0;
    }
  }
  .options {
    margin-bottom: 30px;
    position: relative;
    .little {
      height: 28px !important;
      line-height: 28px !important;
    }
    .switch {
      margin-right: 26px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 28px;
    }
    .name {
      width: 100px;
      height: 50px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 50px;
      text-align: left;
      position: absolute;
      left: 0;
      top: 0;
    }
    .item {
      height: 50px;
      line-height: 50px;
      margin-left: 120px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      position: relative;
      .budget {
        width: 200px;
        margin-right: 20px;
      }
      .picker {
        width: 440px;
      }
      .radio {
        width: 100px;
        height: 40px;
        background: #dedede;
        border-radius: 23px;
        margin-right: 23px;
        float: left;
        line-height: 40px;
        text-align: center;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #999999;
        cursor: pointer;
      }
      .select {
        background: #5ad3cd;
        border-radius: 23px;
        color: #ffffff;
      }
      span {
        display: inline-block;
        margin-left: 20px;
      }
      .file-name {
        padding: 0 10px;
        width: 330px;
        height: 50px;
        border-radius: 4px;
        border: 1px solid #dedede;
        display: inline-block;
        margin-right: 10px;
      }
      .add-file {
        width: 100px;
        height: 50px;
        background: #f1f1f1;
        border-radius: 4px;
        font-size: 40px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 50px;
        text-align: center;
        display: inline-block;
        vertical-align: top;
        cursor: pointer;
        input {
          display: block;
          width: 440px;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: 0;
          cursor: pointer;
          z-index: 1;
        }
        .over {
          cursor: pointer;
          pointer-events: none;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: 0;
          z-index: 2;
        }
      }
    }
  }
  .content {
    padding: 0 31px 0 43px;
  }
  .textarea {
    width: 549px;
    height: 100px;
  }
  .submit {
    background-color: rgb(133, 253, 247);
    width: 446px;
    height: 46px;
    background: #5ad3cd;
    border-radius: 23px;
    margin: 60px 134px 0 220px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 46px;
    text-align: center;
    cursor: pointer;
  }
}
</style>
<style lang="scss">
.budget-unit .el-input {
  width: 200px;
}
.bid-require .textarea .el-textarea__inner {
  width: 549px;
  height: 100px;
}
</style>
