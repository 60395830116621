<template lang="pug">
.set-content
  dialogMask(:show.sync="showDialog", :showClose="true")
    .title
      img(src="../../../../assets/987.png" alt="")
      .title-desc 申请投标
    .content
      .options
        .name 粗略估价
        .item
          el-input.half-helf.right(v-model="form.quotationMin" placeholder="请输入内容")
          el-select.half-helf(v-model="form.quotationMinUnit" placeholder="请选择")
            el-option(label="元" value="元")
            el-option(label="万" value="万")
          span 至
          el-input.half-helf.right(v-model="form.quotationMax" placeholder="请输入内容")
          el-select.half-helf(v-model="form.quotationMaxUnit" placeholder="请选择")
            el-option(label="元" value="元")
            el-option(label="万" value="万")
      .options
        .name 满足投标要求
        .item
          el-switch.switch(
            v-model="form.demandSatisfied"
          )
          span {{ form.demandSatisfied ? '是' : '否' }}
    .submit(@click="submit") 申请投标
    p 投标申请的报价只需简单粗估即可，准确的报价可以在申请通过后，并且需求文档是终稿时在提交。
</template>

<script>
import dialogMask from '@/components/common/dialog.vue'
import {apply} from '@/api/project'
export default {
  name: 'apply',
  props: ['projectId', 'showApplyDialog'],
  data() {
    return {
      showDialog: false,
      form: {
        demandSatisfied: true,
        projectId: 0,
        quotationMax: 0,
        quotationMaxUnit: '元',
        quotationMin: 0,
        quotationMinUnit: '元'
      }
    }
  },
  watch: {
    showApplyDialog(val) {
      if (val) this.showDialog = true
    },
    showDialog(val) {
      if (!val) {
        this.$emit('update:showApplyDialog', false)
        this.reset()
      }
    }
  },
  components: {
    dialogMask
  },
  methods: {
    reset() {
      this.form = {
        demandSatisfied: true,
        projectId: 0,
        quotationMax: 0,
        quotationMaxUnit: '元',
        quotationMin: 0,
        quotationMinUnit: '元'
      }
    },
    compoutePrice(price, unit) {
      if (unit === '元') {
        return price
      } else {
        return price * 10000
      }
    },
    verify() {
      let message = ''
      if (this.form.quotationMax < 0 || this.form.quotationMin < 0) {
        message = '估价需大于0'
      }
      const max = this.compoutePrice(this.form.quotationMax, this.form.quotationMaxUnit)
      const min = this.compoutePrice(this.form.quotationMin, this.form.quotationMinUnit)
      if (Number(max) <= Number(min)) {
        message = '最大估价需大于最低估价'
      }
      if (Number(max) > Number(min) * 3) {
        message = '粗略估价的区间，最大值比最小值的倍数，介于1.1-3倍之间。'
      }
      if (Number(max) < Number(min) * 1.1) {
        message = '粗略估价的区间，最大值比最小值的倍数，介于1.1-3倍之间。'
      }
      if (message) {
        this.$message.error(message)
        return false
      }
      return true
    },
    submit() {
      if (!this.verify()) return
      apply(
        Object.assign(this.form, {
          projectId: this.projectId
        })
      ).then(res => {
        if (res.code === 200) {
          this.$message.success('申请成功')
          this.$emit('uploadSuccess')
          this.showDialog = false
        } else {
          this.$message.error(res.msg)
        }
        console.log('setProjectConfig ', res)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.set-content {
  user-select: none;
  .title {
    padding-left: 20px;
    margin-bottom: 31px;
    img {
      width: 29px;
      height: 32px;
      margin-top: 2px;
      margin-right: 10px;
      display: inline-block;
      font-size: 0;
    }
    .title-desc {
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 33px;
      display: inline-block;
      vertical-align: top;
    }
  }
  .options {
    margin-bottom: 50px;
    position: relative;
    .name {
      width: 120px;
      height: 50px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 50px;
      text-align: left;
      position: absolute;
      left: 0;
      top: 0;
    }
    .item {
      height: 50px;
      line-height: 50px;
      margin-left: 150px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      position: relative;
      .budget {
        width: 200px;
        margin-right: 20px;
      }
      .picker {
        width: 440px;
      }
      span {
        display: inline-block;
        margin: 0 20px;
      }
      .file-name {
        padding: 0 10px;
        width: 330px;
        height: 50px;
        border-radius: 4px;
        border: 1px solid #dedede;
        display: inline-block;
        margin-right: 10px;
      }
      .add-file {
        width: 100px;
        height: 50px;
        background: #f1f1f1;
        border-radius: 4px;
        font-size: 40px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 50px;
        text-align: center;
        display: inline-block;
        vertical-align: top;
        cursor: pointer;
        input {
          display: block;
          width: 440px;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: 0;
          cursor: pointer;
          z-index: 1;
        }
        .over {
          cursor: pointer;
          pointer-events: none;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: 0;
          z-index: 2;
        }
      }
    }
  }
  .content {
    padding: 0 0px 0 40px;
  }
  .textarea {
    height: 180px;
  }
  .submit {
    background-color: rgb(133, 253, 247);
    width: 446px;
    height: 46px;
    background: #5ad3cd;
    border-radius: 23px;
    margin: 60px auto 0;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 46px;
    text-align: center;
    cursor: pointer;
  }
  p {
    width: 428px;
    height: 40px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
    text-align: center;
    margin: 30px auto 10px;
  }
}
</style>
<style lang="scss">
.half-helf {
  width: 100px !important;
  .el-input {
    width: 100px;
  }
}
.right {
  margin-right: 20px;
}
</style>
