<template lang="pug">
.set-content
  dialogMask(:show.sync="showDialog", :showClose="true")
    .title
      img(src="../../../../assets/0000.png" alt="")
      .title-desc 样稿

    .content
      .project-list
        .name 产品设计
        .img-list
          el-image(
            v-for="(item, index) in projectFileList"
            style="width: 100px; height: 200px; margin-right:20px;"
            :preview-src-list="projectPreviewList"
            :src="item.url"
            :key="index"
            fit="contain"
            v-show="!hasTendererAuth"
          )
          el-upload.list(
            v-if="hasTendererAuth"
            action="#"
            :auto-upload="false"
            :file-list="projectFileList"
            list-type="picture-card"
            :before-upload="beforeAvatarUpload"
            :on-preview="handlePictureCardPreviewProject"
            :on-remove="handleRemoveProject"
            :on-change="changeProject",
            :on-exceed="onExceed",
            :limit="10"
          )
            i.el-icon-plus
          el-dialog(:visible.sync="dialogVisibleProject" :modal="false")
            img(width="100%" :src="dialogImageUrlProject" alt="")
      .project-list
        .name 视觉设计
        .img-list
          el-image(
            v-for="(item, index) in ulFileList"
            style="width: 100px; height: 200px;margin-right:20px;"
            :src="item.url"
            fit="contain"
            :key="index"
            :preview-src-list="uiPreviewList"
            v-show="!hasTendererAuth"
          )
          el-upload.list(
            action="#"
            v-if="hasTendererAuth"
            :auto-upload="false"
            :file-list="ulFileList"
            list-type="picture-card"
            :before-upload="beforeAvatarUpload"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemoveProject"
            :on-change="changeUI"
            :disabled="!hasTendererAuth",
            :on-exceed="onExceed"
            :limit="10"
          )
            i.el-icon-plus
          el-dialog(:visible.sync="dialogVisible" :modal="false")
            img(width="100%" :src="dialogImageUrl" alt="")
</template>

<script>
import dialogMask from '@/components/common/dialog.vue'
import {getProductList, delDraft, getUiList, addProductWord, addUiWord} from '@/api/project'
export default {
  name: 'updateWord',
  props: ['projectId', 'showUpdateWord', 'tendererId', 'hasTendererAuth'],
  data() {
    return {
      showDialog: false,
      uiList: [],
      projectList: [],
      dialogVisible: false,
      projectPreviewList: [],
      uiPreviewList: [],
      dialogVisibleProject: false,
      dialogImageUrl: '',
      dialogImageUrlProject: '',
      projectFileList: [],
      ulFileList: []
    }
  },
  watch: {
    showUpdateWord(val) {
      if (val) this.showDialog = true
    },
    showDialog(val) {
      if (!val) {
        this.$emit('update:showUpdateWord', false)
      }
    },
    tendererId() {
      this.init()
    }
  },
  components: {
    dialogMask
  },
  methods: {
    handleRemove(file, fileList) {
      console.log(file, fileList)
    },
    init() {
      getProductList({
        tendererId: this.tendererId
      }).then(res => {
        console.log('var ', res)
        this.projectList = res.data
        const projectFileList = []
        const projectPreviewList = []
        this.projectList.forEach(item => {
          projectFileList.push({
            name: item.id,
            url: item.picUrl
          })
          projectPreviewList.push(item.picUrl)
        })
        this.projectFileList = projectFileList
        this.projectPreviewList = projectPreviewList
      })
      getUiList({
        tendererId: this.tendererId
      }).then(res => {
        console.log('res ', res)
        this.uiList = res.data
        const ulFileList = []
        const uiPreviewList = []
        this.uiList.forEach(item => {
          ulFileList.push({
            name: item.id,
            url: item.picUrl
          })
          uiPreviewList.push(item.picUrl)
        })
        this.ulFileList = ulFileList
        this.uiPreviewList = uiPreviewList
      })
    },
    onExceed() {
      this.$message.error('产品设计和视觉设计，均不可超出10个样稿')
    },
    beforeAvatarUpload(file) {
      var img = file.name.substring(file.name.lastIndexOf('.') + 1)
      const suffix = img === 'jpg'
      const suffix2 = img === 'png'
      const suffix3 = img === 'jpeg'
      const suffix4 = img === 'gif'
      const suffix5 = img === 'bmp'
      const suffix6 = img === 'wbmp'
      const suffix7 = img === 'webp'
      const suffix8 = img === 'tiff'
      if (!suffix && !suffix2 && !suffix3 && !suffix4 && !suffix5 && !suffix6 && !suffix8 && !suffix7) {
        this.$message.error('只能上传图片！')
        return false
      }
      return true
    },
    handleRemoveProject(file, fileList) {
      delDraft({
        projectId: this.projectId,
        draftId: file.name
      }).then(res => {
        if (res.code !== 200) {
          this.$message.error(res.msg)
        } else {
          this.$message.success('移除成功')
        }
      })
      console.log(file, fileList)
    },
    changeProject(file, fileList) {
      if (!this.beforeAvatarUpload(file)) {
        fileList.splice(fileList.length - 1, 1)
        this.projectFileList = fileList
        return
      }
      const params = {
        file: file.raw,
        projectId: this.projectId,
        tenderId: this.tendererId
      }
      addProductWord(params).then(res => {
        if (res.code !== 200) {
          this.$message.error(res.msg)
        } else {
          this.$message.success('添加成功')
          this.$parent.init()
          getProductList({
            tendererId: this.tendererId
          }).then(res => {
            file.name = res.data[res.data.length - 1].id
          })
        }
      })
    },
    changeUI(file, fileList) {
      if (!this.beforeAvatarUpload(file)) {
        fileList.splice(fileList.length - 1, 1)
        this.ulFileList = fileList
        return
      }
      const params = {
        file: file.raw,
        projectId: this.projectId,
        tenderId: this.tendererId
      }
      addUiWord(params).then(res => {
        if (res.code !== 200) {
          this.$message.error(res.msg)
        } else {
          this.$parent.init()
          this.$message.success('添加成功')
          getUiList({
            tendererId: this.tendererId
          }).then(res => {
            file.name = res.data[res.data.length - 1].id
          })
        }
      })
    },
    handlePictureCardPreviewProject(file) {
      this.dialogImageUrlProject = file.url
      this.dialogVisibleProject = true
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.set-content {
  user-select: none;
  .title {
    padding-left: 20px;
    margin-bottom: 60px;
    img {
      width: 29px;
      height: 32px;
      margin-top: 2px;
      margin-right: 10px;
      display: inline-block;
      font-size: 0;
    }
    .title-desc {
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 33px;
      display: inline-block;
      vertical-align: top;
    }
  }
  .content {
    width: 100%;
    padding: 0 68px 0 68px;
    .project-list {
      margin-bottom: 40px;
    }
    .name {
      width: 80px;
      float: left;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 28px;
    }
    .img-list {
      margin-left: 111px;
      // .list {
      //   width: 100px;
      //   height: 200px;
      //   margin-right: 30px;
      //   display: inline-block;
      // }
    }
  }
}
</style>
<style lang="scss">
.img-list {
  .el-upload--picture-card,
  .el-upload-list--picture-card .el-upload-list__item {
    width: 100px;
    height: 200px;
    .el-icon-plus {
      line-height: 200px;
    }
  }
}
</style>
