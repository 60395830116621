/* eslint-disable no-debugger */
<template lang="pug">
.comp-info-box
  .comp-info-container
    .comp-info-point
    .comp-info-title 竞标信息
  .comp-info-basic-container
    .comp-info-basic 截止时间：
    .comp-info-basic-info {{formatTimeFilter(info.bidDeadline, 'YYYY-MM-DD')}}
  .comp-info-basic-container
    .comp-info-basic 剩余时间：
    .comp-info-basic-info {{deadTime}}
  .comp-info-basic-container(v-for="(item, index) in compInfoms" :key="`${index}compInfo`")
    .comp-info-basic {{item.label}}：
    .comp-info-basic-info {{item.value}}
      .detail(v-if="item.value !== '' && item.label === '平台估价'", @click="checktDetail") 详细...
      .detail(v-if="item.value !== '' && item.label === '质检标准'", @click="toQuanqingStandard") 详细
        span(v-if="(jurisdictionCode.includes(0) || jurisdictionCode.includes(1))") /更改
        span() ...
  .btn-list(v-if="(jurisdictionCode.includes(0) || jurisdictionCode.includes(1)) && statusList[status] !== '已停止'")
    el-button.btn(
      @click="handleSetCompInfo",
      v-if="(jurisdictionCode.includes(0) || jurisdictionCode.includes(1))"
      type="primary",
    ) 设置
    el-button.btn(@click="handleComp(true)" v-if="!info.isBidding") 开启竞标
    el-button.btn(@click="handleComp(false)" v-if="info.isBidding") 停止竞标
    el-button.btn(@click="handleSetEvaluate" v-if="jurisdictionCode.includes(1)" type="primary") 设置平台估价
    el-button.btn(@click="setshowQualityStandardsDialog" v-if="!info.isBidding") 设置质检标准
    //- v-if="jurisdictionCode === 1"
  setInfoDialog(
    :projectId="projectId",
    :info="info"
    :showSetDialog.sync="showSetDialog",
    @uploadSuccess="uploadSuccess",
  )
  evaluateDetailDialog(
    :projectId="projectId",
    :showEvaluateDialog.sync="showEvaluateDetailDialog",
    :jurisdictionCode="jurisdictionCode"
    @uploadSuccess="uploadSuccess",
    :list="list"
  )
  evaluateDialog(
    :projectId="projectId",
    :showEvaluateDialog.sync="showEvaluateDialog",
    @uploadSuccess="uploadSuccess",
    :info="info"
  )
  qualityStandardsDialog(
    :projectId="projectId",
    :payedOrderId="payedOrderId"
    :standardPackageId="standardPackageId",
    :showQualityDialog.sync="showQualityStandardsDialog",
    @uploadSuccess="uploadSuccess",
  )
</template>

<script>
import qualityStandardsDialog from '@/components/project/projectCompInfo/dialog/ualityStandards.vue'
import setInfoDialog from '@/components/project/projectCompInfo/dialog/setInfo.vue'
import evaluateDialog from '@/components/project/projectCompInfo/dialog/evaluate.vue'
import evaluateDetailDialog from '@/components/project/projectCompInfo/dialog/evaluateDetail.vue'
import { getProComInfo, setProjectStatus, checkPlatformQuotation, getPlatformList } from '@/api/project'
import { competitiveStatus, statusList } from '@/config/status.js'
import { formatTimeFilter } from '@/utils/index.js'

export default {
  name: 'compInfo',
  props: ['projectId', 'jurisdictionCode', 'status'],
  data() {
    return {
      compInfoms: [],
      info: {},
      list: [],
      showSetDialog: false,
      showEvaluateDialog: false,
      competitiveStatus,
      evaluateDialog: false,
      showEvaluateDetailDialog: false,
      showQualityStandardsDialog: false,
      platformQuotation: [],
      statusList,
      deadTime: '',
      standardPackageId: '',
      payedOrderId: '',
    }
  },
  components: {
    setInfoDialog,
    evaluateDialog,
    evaluateDetailDialog,
    qualityStandardsDialog,
  },
  watch: {
    info(val) {
      if (val.bidDeadline) {
        this.computedTime()
        setInterval(() => {
          this.computedTime()
        }, 60000)
      }
    }
  },
  created() {
    this.init()
  },
  methods: {
    computedTime() {
      const deadTime = new Date(this.info.bidDeadline.replace(/-/g, '/')).getTime()
      const now = new Date().getTime()
      const short = deadTime - now
      if (short <= 0) {
        this.deadTime = '已截止'
      } else {
        const day = parseInt(short / 86400000)
        const hours = parseInt((short - day * 86400000) / 3600000)
        const minute = parseInt((short - day * 86400000 - hours * 3600000) / 60000)
        console.log('day ', day)
        console.log('hours ', hours)
        console.log('minute ', minute)
        this.deadTime = `${day > 0 ? `${day}天` : ''}${hours > 0 ? `${hours}小时` : ''}${minute > 0 ? `${minute}分钟` : ''
          }`
      }
    },
    formatTimeFilter(time, format) {
      return formatTimeFilter(time, format)
    },
    init() {
      const params = {
        projectId: this.projectId
      }
      getProComInfo(params)
        .then(res => {
          if (res.code === 200) {
            const info = res.data
            const result = []
            const day = info.customerDurationExceptionDay ? `${info.customerDurationExceptionDay}天` : ''
            const month = info.customerDurationExceptionMonth ? `${info.customerDurationExceptionMonth}个月` : ''
            this.standardPackageId = info.standardPackageId || ''
            this.payedOrderId = info.standardOrderId || ''
            result.push({
              label: '项目预算',
              value: info.projectBudget ? this.resetBudget(info.projectBudget) : ''
            })
            result.push({
              label: '工期期望',
              value: month + day
            })
            let platformQuotations = 0
            info.platformQuotations.forEach(element => {
              platformQuotations = platformQuotations + element
            })
            if (info.platformQuotations.length > 1) {
              var maxValue = Math.max.apply(Math, info.platformQuotations)
              var minValue = Math.min.apply(Math, info.platformQuotations)
              result.push({
                label: '平台估价',
                value: `${maxValue > minValue
                  ? `${this.resetBudget(minValue)}-${this.resetBudget(maxValue)}元`
                  : `${this.resetBudget(maxValue)}`
                  }`
              })
            } else if (info.platformQuotations.length === 1) {
              result.push({
                label: '平台估价',
                value: this.resetBudget(info.platformQuotations[0])
              })
            } else if (info.platformQuotations.length === 0) {
              result.push({
                label: '平台估价',
                value: ''
              })
            }
            result.push({
              label: '质检标准',
              value: info.qualityStandard || '暂不需要' || '待设置',
            })
            result.push({
              label: '其他备注',
              value: info.customerRemark
            })
            this.$set(this, 'info', res.data)
            this.$set(this, 'compInfoms', result)
          }
        })
        .catch(err => err)
      checkPlatformQuotation(params).then(res => {
        this.platformQuotation = res.data
      })
      getPlatformList(params).then(res => {
        this.list = res.data
        console.log('list ', res.data)
      })
    },
    resetBudget(budget) {
      if (budget === '') return '未知'
      if (budget < 10000) return `${budget}元`
      return `${budget / 10000}万`
    },
    handleSetCompInfo() {
      this.showSetDialog = true
    },
    uploadSuccess() {
      this.showSetDialog = false
      this.showQualityStandardsDialog = false
      this.init()
    },
    handleComp(status) {
      setProjectStatus({
        projectId: this.projectId,
        status
      }).then(res => {
        if (res.code === 200) {
          this.$message.success(`${status ? '已开启竞标' : '已停止竞标'}`)
          this.init()
          this.$emit('uploadAuth')
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handleSetEvaluate() {
      this.showEvaluateDialog = true
    },
    checktDetail() {
      this.showEvaluateDetailDialog = true
    },
    setshowQualityStandardsDialog() {
      this.showQualityStandardsDialog = true
    },
    toQuanqingStandard() {
      this.$router.push(`/quanqingStandard?projectId=${this.projectId}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.comp-info-box {
  margin: 40px 0;

  .comp-info-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .comp-info-point {
      width: 9px;
      height: 9px;
      background: #5ad3cd;
      margin: 10px 11px 10px 0;
      border-radius: 50%;
    }

    .comp-info-title {
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }
  }

  .comp-info-basic-container {
    margin: 0 0 16px 0;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    display: flex;

    .comp-info-basic {
      color: #999999;
      margin-right: 10px;
      white-space: nowrap;
    }

    .comp-info-basic-info {
      color: #333333;

      .detail {
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #5ad3cd;
        line-height: 25px;
        display: inline-block;
        margin-left: 10px;
        cursor: pointer;
      }
    }

    .comp-info-basic-detail {
      margin-left: 10px;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #5ad3cd;
    }
  }

  .btn-list {
    overflow: hidden;
    margin-top: 20px;
    margin-bottom: 40px;

    .btn {
      min-width: 80px;
      background: #5ad3cd;
      padding: 0 17px;
      border-radius: 15px;
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
      line-height: 26px;

      span {
        line-height: 26px;
      }
    }
  }
}
</style>
