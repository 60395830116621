/* eslint-disable no-debugger */
<template lang="pug">
.project-member(v-if="ownStatus !== 8")
  .member-box
    h4.title(clang="member-box-title") 项目成员 
    .add(@click="set" v-if="status !== '已停止' && (jurisdictionCode.includes(0) || jurisdictionCode.includes(1) || jurisdictionCode.includes(9) || jurisdictionCode.includes(8) || (jurisdictionCode.includes(2) && (jurisdictionCode.includes(4) || jurisdictionCode.includes(5)  || jurisdictionCode.includes(7))))") 
      span 设置
    el-divider.el-divider
    el-timeline.el-timeline(style="margin-top:30px")
      el-timeline-item(color="#37C2BC" v-if="manageList.length")
        h4.min-class-title 主管
        .member-box-container
          teamMemberItem(
            v-for="(item, i) in manageList"
            :key="i"
            :detail="item"
            :isManager="false"
          )
      el-timeline-item(color="#37C2BC" v-if="ProductManager.length")
        h4.min-class-title 产品
        .member-box-container
          teamMemberItem(
            v-for="(item, i) in ProductManager"
            :key="i"
            :detail="item"
            :isManager="false"
          )
      el-timeline-item(color="#37C2BC" v-if="nomalMemberList.length")
        h4.min-class-title 技术
        .member-box-container
          teamMemberItem(
            v-for="(item, i) in nomalMemberList"
            :key="i"
            :detail="item"
            :isManager="false"
          )
  addMember(
    :showMemberDialog.sync="showDialog" 
    :jurisdictionCode="jurisdictionCode" 
    :tendererId="tendererId"
    :projectId="projectId" 
    @uploadSuccess="uploadSuccess"
  )
</template>

<script>
import {getDescList, getProjectAuth} from '@/api/project'
import addMember from './addMember'
import teamMemberItem from './teamMemberItem'
export default {
  name: 'compInfo',
  props: ['projectId', 'jurisdictionCode', 'status', 'tendererId'],
  data() {
    return {
      memberList: [],
      nomalMemberList: [],
      ProductManager: [],
      manageList: [],
      ownStatus: '',
      showDialog: false
    }
  },
  components: {
    addMember,
    teamMemberItem
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      const params = {
        projectId: this.projectId
      }
      getDescList(params)
        .then(res => {
          const nomalMemberList = []
          const ProductManager = []
          const manageList = []
          res.data.forEach(item => {
            if (item.type === 1) {
              manageList.push(item)
            }
            if (item.type === 2) {
              ProductManager.push(item)
            }
            if (item.type === 3) {
              nomalMemberList.push(item)
            }
          })
          this.manageList = manageList
          this.nomalMemberList = nomalMemberList
          this.ProductManager = ProductManager
          console.log('res ', res)
        })
        .catch(err => err)
      getProjectAuth(params).then(res => {
        this.ownStatus = res.data
        console.log('res ', res)
      })
    },
    set() {
      this.showDialog = true
    },
    uploadSuccess() {
      this.init()
    }
  }
}
</script>

<style lang="scss" scoped>
.project-member {
  background: #ffffff;
  border-radius: 8px;
  padding: 25px 80px 54px 80px;
  position: relative;
  margin-bottom: 20px;
  .title {
    height: 30px;
    font-size: 22px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 30px;
  }
  .el-divider {
    margin: 30px 0 30px 0;
  }
  h4 {
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
  }
  .el-timeline-item__node--normal {
    top: 5px;
  }
  .member-item-box {
    cursor: pointer;
  }
  .add {
    width: 80px;
    height: 40px;
    background: #5ad3cd;
    border-radius: 2px;
    text-align: center;
    float: right;
    font-size: 16px;
    line-height: 40px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    cursor: pointer;
    position: absolute;
    top: 30px;
    right: 80px;
  }
}
</style>
