<template lang="pug">
.change-info
  dialogMask(:show.sync="showDialog", :showClose="true")
    .title
      img(src="../../../../assets/jingbiao2x.png" alt="")
      p 投标1
    .content
      .options
        .name.required {{finalVersion ? '精确报价' : '项目报价'}}
        .item(v-if="!finalVersion")
          el-input.half-helf.right(v-model="quotationMin" placeholder="请输入内容")
          el-select.half-helf(v-model="quotationMinUnit" placeholder="请选择")
            el-option(label="元" value="元")
            el-option(label="万" value="万")
          span 至
          el-input.half-helf.right(v-model="quotationMax" placeholder="请输入内容")
          el-select.half-helf(v-model="quotationMaxUnit" placeholder="请选择")
            el-option(label="元" value="元")
            el-option(label="万" value="万")
        .item(v-if="finalVersion")
          el-input.half.right(v-model="quotation" placeholder="请输入内容")
          el-select.half(v-model="quotationUnit" placeholder="请选择")
            el-option(label="元" value="元")
            el-option(label="万" value="万")
      .options
        .name.required 工作量
        .item
          el-input.half.right(v-model="form.workload")
          el-select.half(v-model="form.workloadUnit")
            el-option(label="人天" value="1")
            el-option(label="小时" value="2")
      .options
        .name.required 项目工期
        .item
          el-select.budget-unit(v-model="form.durationMonth" placeholder="请选择")
            el-option(label="0个月" value="0")
            el-option(label="1个月" value="1")
            el-option(label="2个月" value="2")
            el-option(label="3个月" value="3")
            el-option(label="4个月" value="4")
            el-option(label="5个月" value="5")
            el-option(label="6个月" value="6")
            el-option(label="7个月" value="7")
            el-option(label="8个月" value="8")
            el-option(label="9个月" value="9")
            el-option(label="10个月" value="10")
            el-option(label="11个月" value="11")
            el-option(label="12个月" value="12")
          el-select.budget-unit(v-model="form.durationDay" placeholder="请选择")
            el-option(label="0天" value="0")
            el-option(label="1天" value="1")
            el-option(label="2天" value="2")
            el-option(label="3天" value="3")
            el-option(label="4天" value="4")
            el-option(label="5天" value="5")
            el-option(label="6天" value="6")
            el-option(label="7天" value="7")
            el-option(label="8天" value="8")
            el-option(label="9天" value="9")
            el-option(label="10天" value="10")
            el-option(label="11天" value="11")
            el-option(label="12天" value="12")
            el-option(label="13天" value="13")
            el-option(label="14天" value="14")
            el-option(label="14天" value="14")
            el-option(label="15天" value="15")
            el-option(label="16天" value="16")
            el-option(label="17天" value="17")
            el-option(label="18天" value="18")
            el-option(label="19天" value="19")
            el-option(label="20天" value="20")
            el-option(label="21天" value="21")
            el-option(label="22天" value="22")
            el-option(label="23天" value="23")
            el-option(label="24天" value="24")
            el-option(label="25天" value="25")
            el-option(label="26天" value="26")
            el-option(label="27天" value="27")
            el-option(label="28天" value="28")
            el-option(label="29天" value="29")
            el-option(label="30天" value="30")
      .options
        .name 满足标准
        .item
          el-select.budget-unit(v-model="form.standardSatisfactionRate" placeholder="请选择")
            el-option(label="50%" value="50")
            el-option(label="55%" value="55")
            el-option(label="60%" value="60")
            el-option(label="65%" value="65")
            el-option(label="70%" value="70")
            el-option(label="75%" value="75")
            el-option(label="80%" value="80")
            el-option(label="85%" value="85")
            el-option(label="90%" value="90")
            el-option(label="95%" value="95")
            el-option(label="100%" value="100")
      .options
        .name.little 案例真实性
        .item.little
          el-switch.switch(
            v-model="form.realCase"
          )
          | {{ form.realCase ? '保真，支持核验' : '暂无此项承诺' }}
      .options
        .name.little 源文件
        .item.little
          el-switch.switch(
            v-model="form.provideSourceCode"
          )
          | {{ form.provideSourceCode ? '提供完整源文件，包括各设计文档和源代码' : '暂不提供此项' }}
      .options
        .name.little 代码解说
        .item.little
          el-switch.switch(
            v-model="form.provideCodeExplication"
          )
          | {{ form.provideCodeExplication ? '配备代码、数据库等解说视频' : '暂不提供此项' }}
      .options
        .name 附言
        .item
          el-input.textarea(
            type="textarea"
            placeholder="请输入内容"
            v-model="form.postscript"
          )     
    .submit(@click="submit") 投标
</template>

<script>
import dialogMask from '@/components/common/dialog.vue'
import {tenderUpdate} from '@/api/project'
export default {
  name: 'apply',
  props: ['projectId', 'showChangeDialog', 'tendererId', 'info', 'finalVersion'],
  data() {
    return {
      showDialog: false,
      form: {
        postscript: '',
        durationDay: '',
        durationMonth: '',
        provideCodeExplication: false,
        provideSourceCode: false,
        realCase: false,
        standardSatisfactionRate: '',
        workload: '',
        workloadUnit: '',
        quotation: '',
        quotationMin: '',
        quotationMax: ''
      },
      quotation: '',
      quotationMax: '',
      quotationMaxUnit: '元',
      quotationMin: '',
      quotationMinUnit: '元',
      quotationUnit: '元'
    }
  },
  watch: {
    showChangeDialog(val) {
      if (val) {
        this.showDialog = true
        this.form = {
          postscript: this.info.postscript,
          durationDay: this.info.durationDay ? this.info.durationDay.toString() : this.info.durationDay !== 0 ? '' : 0,
          durationMonth: this.info.durationMonth
            ? this.info.durationMonth.toString()
            : this.info.durationMonth !== 0
            ? ''
            : 0,
          provideCodeExplication: this.info.provideCodeExplication,
          provideSourceCode: this.info.provideSourceCode,
          realCase: this.info.realCase,
          standardSatisfactionRate: this.info.standardSatisfactionRate,
          workload: this.info.workload,
          workloadUnit: this.info.workloadUnit
            ? this.info.workloadUnit.toString()
            : this.info.workloadUnit !== 0
            ? ''
            : 0,
          quotationMin: this.info.quotationMin,
          quotationMax: this.info.quotationMax,
          quotation: this.info.quotation
        }
        this.quotationMax = this.changPrice(this.info.quotationMax)
        this.quotationMaxUnit = this.changUnit(this.info.quotationMax)
        this.quotationMin = this.changPrice(this.info.quotationMin)
        this.quotationMinUnit = this.changUnit(this.info.quotationMin)
        this.quotation = this.changPrice(this.info.quotation)
        this.quotationUnit = this.changUnit(this.info.quotation)
      }
    },
    showDialog(val) {
      if (!val) {
        this.$emit('update:showChangeDialog', false)
        this.reset()
      }
    }
  },
  components: {
    dialogMask
  },
  methods: {
    changUnit(price) {
      if (price > 10000) {
        return '万'
      }
      return '元'
    },
    changPrice(price) {
      if (price > 10000) {
        return price / 10000
      }
      return price
    },
    reset() {
      this.showDialog = false
      this.form = {
        postscript: '',
        durationDay: '',
        durationMonth: '',
        quotation: '',
        provideCodeExplication: false,
        provideSourceCode: false,
        realCase: false,
        standardSatisfactionRate: '',
        workload: '',
        workloadUnit: '',
        quotationMin: '',
        quotationMax: ''
      }
      this.quotation = ''
      this.quotationUnit = '元'
      this.quotationMax = ''
      this.quotationMaxUnit = '元'
      this.quotationMin = ''
      this.quotationMinUnit = '元'
    },
    compoutePrice(price, unit) {
      if (unit === '元') {
        return Number(price)
      } else {
        return Number(price) * 10000
      }
    },
    verify() {
      let message = ''
      if (Number(this.form.workload) < 0 || this.form.workload?.toString().indexOf('.') !== -1) {
        message = '工作量请输入正整数'
      }
      if (!this.finalVersion) {
        const max = Number(this.compoutePrice(this.quotationMax, this.quotationMaxUnit))
        const min = Number(this.compoutePrice(this.quotationMin, this.quotationMinUnit))
        if (max < min) {
          message = '最大估价需大于最低估价'
        }
        if (max < min * 1.1) {
          message = '粗略估价的区间，最大值比最小值的倍数，介于1.1-3倍之间。'
        }
        if (max > min * 3) {
          message = '粗略估价的区间，最大值比最小值的倍数，介于1.1-3倍之间。'
        }
        if (min < 0) {
          message = '项目报价请输入大于零的数值'
        }
        if (max < 0) {
          message = '项目报价请输入大于零的数值'
        }
      } else {
        if (this.quotation === '') {
          message = '请输入精确估价'
        }
        if (this.quotation <= 0) {
          message = '精确估价需大于等于0'
        }
      }
      if (message) {
        this.$message.error(message)
        return false
      }
      return true
    },
    submit() {
      if (!this.verify()) return
      const params = Object.assign({}, this.form, {
        quotationMin: this.quotationMinUnit === '万' ? this.quotationMin * 10000 : this.quotationMin,
        quotationMax: this.quotationMaxUnit === '万' ? this.quotationMax * 10000 : this.quotationMax,
        quotation: this.compoutePrice(this.quotation, this.quotationUnit),
        id: this.tendererId,
        workload: Number(this.form.workload),
        workloadUnit: Number(this.form.workloadUnit),
        durationDay: Number(this.form.durationDay),
        durationMonth: Number(this.form.durationMonth),
        projectId: this.projectId
      })
      tenderUpdate(params).then(res => {
        console.log('res ', res)
        if (res.code === 200) {
          this.$emit('uploadSuccess')
          this.$message.success('修改成功')
          this.showDialog = false
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.change-info {
  user-select: none;
  .required:before {
    content: '*';
    color: #f56c6c;
    margin-right: 4px;
  }
  .switch {
    margin-right: 26px;
  }
  .title {
    padding-left: 20px;
    margin-bottom: 60px;
    img {
      width: 31px;
      height: 30px;
      margin-top: 2px;
      margin-right: 10px;
      display: inline-block;
      font-size: 0;
    }
    p {
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 33px;
      display: inline-block;
      vertical-align: top;
    }
  }
  li {
    padding-bottom: 20px;
    margin-bottom: 40px;
    border-bottom: 1px solid #f1f1f1;
    &:last-child {
      border-bottom: 0;
    }
  }
  .options {
    margin-bottom: 50px;
    position: relative;
    .name {
      width: 120px;
      height: 50px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 50px;
      text-align: left;
      position: absolute;
      left: 0;
      top: 0;
      text-align: right;
    }
    .item {
      line-height: 50px;
      margin-left: 150px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      position: relative;
      .budget {
        width: 200px;
        margin-right: 20px;
      }
      .picker {
        width: 440px;
      }
      span {
        display: inline-block;
        margin: 0 20px;
      }
      .file-name {
        padding: 0 10px;
        width: 330px;
        height: 50px;
        border-radius: 4px;
        border: 1px solid #dedede;
        display: inline-block;
        margin-right: 10px;
      }
      .add-file {
        width: 100px;
        height: 50px;
        background: #f1f1f1;
        border-radius: 4px;
        font-size: 40px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 50px;
        text-align: center;
        display: inline-block;
        vertical-align: top;
        cursor: pointer;
        input {
          display: block;
          width: 440px;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: 0;
          cursor: pointer;
          z-index: 1;
        }
        .over {
          cursor: pointer;
          pointer-events: none;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: 0;
          z-index: 2;
        }
      }
    }
  }
  .content {
    padding: 0 0px 0 40px;
  }
  .submit {
    background-color: rgb(133, 253, 247);
    width: 446px;
    height: 46px;
    background: #5ad3cd;
    border-radius: 23px;
    margin: 60px 134px 0 192px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 46px;
    text-align: center;
    cursor: pointer;
  }
}
</style>
<style lang="scss">
.budget-unit .el-input {
  width: 200px;
}
.bid-require .textarea .el-textarea__inner {
  width: 549px;
  height: 100px;
}
</style>
