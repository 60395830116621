<template>
  <div class="member-item-box">
    <div>
      <avaderImg
        @click.native="gotoCompanyDetailPage(detail.id)"
        :imgUrl="detail.smallHeadPicUrl"
        :sex="detail.gender == 1 ? true : false"
        :size="60"
      ></avaderImg>
      <ul class="memberDetailList">
        <li class="manager-box">
          <h4 @click="gotoCompanyDetailPage(detail.id)">{{ detail.nickName }}</h4>
          <div>
            <b class="manager-tag" v-if="showManagerTag && detail.managerInfo.isTeamManager">团队负责人</b>
            <b class="manager-tag" v-if="showManagerTag && detail.managerInfo.isProductManager">产品负责人</b>
            <b class="manager-tag" v-if="showManagerTag && detail.managerInfo.isTechniqueManager">技术负责人</b>
          </div>
        </li>
        <li class="detail-box">
          <span>{{ detail.currentCompanyVisible ? detail.currentCompany : '****' }}</span
          >丨 <span> {{ detail.occupation | EmployeeOccupationListFilter }}</span
          >丨<span>{{ detail.workYears }}年</span>
        </li>
        <li class="text-button" v-if="detail.workedCompany && detail.workedCompany.length">
          <companytag v-for="(item, i) in detail.workedCompany" :key="i" :text="item | CompanyListFilter" v-if="i < 3">
          </companytag>
        </li>
        <li class="text-button-round" v-if="detail.domains && detail.domains.length">
          <domaintag
            v-for="(item, i) in detail.domains"
            :key="i"
            :text="item | EmployeeDomainListFilter"
            v-if="i < 3"
          ></domaintag>
        </li>
        <li v-if="isManager" class="action-box">
          <!-- 不能设置自己，管理员可见   v-if="!isOwner && isManager"  -->
          <el-popover placement="bottom-right" width="150" trigger="hover" v-model="visible">
            <div class="popover-content-box">
              <li v-for="(menber, i) in roleEnums" :key="i">
                <button class="btn-role-btn" type="primary" size="mini">
                  {{ menber.label }}
                </button>
                <!-- @click="teamModifyRole(detail.managerInfo.teamId, detail.id, menber.value)" -->
              </li>
            </div>
            <img :src="editMember" slot="reference" alt="ss" @click="visible = true" />
          </el-popover>
          <!-- <img :src="dealMember" alt="ss" @click="teamDelMember(detail.managerInfo.teamId, detail.id)" /> -->
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
// import {teamModifyRoleApi, teamDelMemberApi} from '../../api/team'
export default {
  name: 'teamMemberItem',
  props: {
    detail: {
      default() {
        return {
          workedCompany: []
        }
      }
    },
    showManagerTag: {
      default() {
        return false
      }
    },
    isManager: {
      default() {
        return false
      }
    }
  },
  computed: {
    ...mapState(['UserInfo', 'AreaList', 'BusinessList', 'EducationList', 'EmployeeDomainList']),
    isOwner() {
      // return this.UserInfo.employeeId == this.manager.employeeId
      return false
    },

    teamId() {
      return this.$route.query.id
    }
  },
  data() {
    return {
      // （0-普通成员；1-团队负责人；2-产品负责人；3-技术负责人）
      roleEnums: [
        {label: '设为 普通成员  ', value: '0'},
        {label: '设为 团队负责人', value: '1'},
        {label: '设为 产品负责人', value: '2'},
        {label: '设为 技术负责人', value: '3'}
      ],
      visible: false,
      editMember: '/img/编辑.png',
      dealMember: 'img/X.png'
    }
  },
  mounted() {},
  methods: {
    ...mapActions(['ChangeUserInfoActions']),
    // teamModifyRole(teamId, employeeId, role) {
    //   this.visible = false
    //   if (role == 1) {
    //     this.$confirm('设置他人为团队负责人，您将变成普通成员，确定吗？').then(_ => {
    //       this.teamModifyRoleApi(teamId, employeeId, role)
    //     })
    //   } else {
    //     this.teamModifyRoleApi(teamId, employeeId, role)
    //   }
    // },
    // teamModifyRoleApi(teamId, employeeId, role) {
    //   teamModifyRoleApi({teamId, employeeId, role})
    //     .then(res => {
    //       console.log('%c 修改成员角色....', 'color: red; font-style: italic', JSON.stringify(res, null, 2))
    //       if (res.data) {
    //         this.$message(res.data)
    //       } else {
    //         this.$message('操作成功')
    //       }
    //       this.$emit('getTeamDetail')
    //       this.ChangeUserInfoActions()
    //     })
    //     .catch(err => {
    //       console.log('%c 修改成员角色....', 'color: red; font-style: italic', JSON.stringify(err, null, 2))
    //     })
    // },
    // teamDelMember(teamId, employeeId) {
    //   this.$confirm('确认删除此成员吗？')
    //     .then(_ => {
    //       teamDelMemberApi({teamId, employeeId})
    //         .then(res => {
    //           if (res.data) {
    //             this.$message(res.data)
    //           } else {
    //             this.$message('删除成功！')
    //           }
    //           console.log('%c 删除成员....', 'color: red; font-style: italic', JSON.stringify(res, null, 2))
    //           this.$emit('getTeamDetail')
    //         })
    //         .catch(err => {
    //           console.log('%c 删除成员....', 'color: red; font-style: italic', JSON.stringify(err, null, 2))
    //         })
    //     })
    //     .catch(_ => {})
    // },
    gotoCompanyDetailPage(id) {
      this.$router.push({
        path: `/personal/detail/${id}`
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.popover-content-box {
  width: 120px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  /deep/ .el-button {
    width: 110px;
    margin: 5px;
  }
}
.manager-box {
  display: flex;
  h4 {
    height: 45px;
    line-height: 45px;
  }
}
.manager-tag {
  display: inline-block;
  position: relative;
  margin-right: 3px;
  left: 10px;
  top: 0px;
  padding: 3px 10px;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  background: #5ad3cd;
  border-radius: 3px;
}
.manager-tag:before {
  content: '';
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 10px 10px 0 3px;
  border-color: #5ad3cd transparent transparent transparent;
  position: absolute;
  bottom: -7px;
  left: 15px;
}
.member-item-box {
  padding-left: 20px;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 450px;
  display: inline-block;
  > div {
    display: flex;
  }
  .memberDetailList {
    flex: 1;
    margin-left: 16px;
  }
  h4 {
    height: 25px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 25px;
    margin-right: 10px;
  }
}
.detail-box {
  font-size: 17px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.text-button {
  height: 26px;
  margin-top: 15px;
  margin-bottom: 10px;
}
.text-button-round {
  height: 22px;
  margin-top: 12px;
  margin-bottom: 10px;
}
.action-box {
  height: 20px;
  line-height: 20px;
  img {
    margin-right: 21px;
    margin-top: 10px;
  }
}

/deep/ .el-popover {
  background: #f6f6f6;
}

.btn-role-btn {
  width: 120px;
  height: 30px;
  background: #5ad3cd;
  border: none;
  outline: none;
  margin-bottom: 6px;
  margin-right: 6px;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  border-radius: 3px;
}
</style>
