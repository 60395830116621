<template lang="pug">
  .content
    layoutContainer
      <div v-if="SearchInput" class="SearchInput"> {{ SearchInput }}
        <i @click="$store.commit('ChangeSearchInput', '')" :class="'el-icon-error close-icon'"></i>
      </div>
      checkRadioGroup(title="承接开发"
        :list="businessList.softwareDevelopmentList"
        :activeIndex.sync="queryParams.businessTypes"
        keyname="name"
      )
      checkRadioGroup(title="项目状态"
        :list="projectStatusList"
        :activeIndex.sync="queryParams.statusTypes"
        keyname="name"
      )
      checkRadioGroup(title="合作方式"
        :list="teamworkList"
        :activeIndex.sync="queryParams.cooperationWayTypes"
        keyname="name"
      )
      checkRadioGroup(title="委托环节"
        :list="partList"
        :activeIndex.sync="queryParams.entrustPartTypes"
        keyname="name"
      )

      .project
        ul
          li.list(v-for="(item, index) in projectList" :key="`${index}${item.id}`" @click="goToProject(item.id)")
            .project-type {{businessList.softwareDevelopmentList[item.businessType - 1] && businessList.softwareDevelopmentList[item.businessType - 1].name}}
            .project-name {{item.name}}
            .project-introduction {{item.introduction}}
            .project-status_info
              p {{setStatus(item.status)}}
              span(v-if="item.bidTenderCount && item.bidTenderCount > 0") {{item.bidTenderCount}}投标
              span(v-if="item.bidAveragePrice && item.bidAveragePrice > 0" class="price-tag")
                e(class="left") · 
                b(class="mid") {{setPriceNum(item.bidAveragePrice)}}
                e(class="right") (均)
              span(v-if="item.status === 320 && item.bidDeadline") · {{computedTime(item.bidDeadline) < 0 ? `已截止` : `${computedTime(item.bidDeadline)}天后截止`}}
            .project-entrust_part
              .part-red(v-if="item.demandRealCase") 案例保真
              .part-red(v-if="item.demandDraft") 样例有偿
              .part(v-if="item.cooperationWay === 2") 联合开发
              //- .part(v-if="item.entrustPart.length === 0") 整体委托
              .part(v-if="!item.entrustPart.includes(1) && item.entrustPart.length > 0" v-for="part in item.entrustPart") {{handlepart(part)}}
            .company-info
              .budget {{setPriceNum(item.budget, '未知')}}
                span 预算
              .customer-name {{item.customerName}}
                img.location(src="./../../assets/location.png")
              .publish-time {{formatTimeFilter(item.publishDate, 'MM.DD')}}
        .empty-img
          img.empty(src="./../../assets/listempty.png" v-if="total === 0 && loaded" )

        .left-column
          .publish-options
            .publish-project_btn(@click="publish") 发布项目
            .publish-type_list(
              v-for="(item) in publishInfoList" 
              :class="{selected: currentUserParticipateProjectStatus === item.val}"
              @click="setPublishType(item.val)"
            ) {{item.name}}
          .hot-project
            .title 热门项目
            ul
              li(
                v-for="item in hotProject",
                :key="item.id"
                @click="goToProject(item.id)"
              )
                .project-type {{businessList.softwareDevelopmentList[item.businessType - 1].name.toString().charAt(0)}}
                .project-name {{item.name}}
                span {{setStatus(item.status)}}
                .project-budget {{setPriceNum(item.budget)}}
      el-pagination.pagination(
        v-if="total !== 0"
        background
        layout="prev, pager, next"
        :current-page.sync="page"
        :page-size="size"
        :total="total"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        @current-change="currentChange"
      )
    publishDialog(
      :showPublish.sync="showPublishDialog", 
      :partList="partList",
      :businessList="businessList.softwareDevelopmentList",
      :teamworkList="teamworkList"
      @publishSuccess="publishSuccess"
    )

</template>

<script>
// import moreButton from '@/components/more-button.vue'
import {projectList, getHotProject, getProjectStatus, projectEntrustPart} from '@/api/project'
import {getBusinessListApi} from '@/api/index'
import {statusList} from '@/config/status.js'
import {setPriceNum, formatTimeFilter} from '@/utils/index'
import publishDialog from '@/components/project/publishDialog'
// import marketing from '../components/marketing'
import {mapState} from 'vuex'

export default {
  name: 'Project',
  components: {
    publishDialog
  },
  data() {
    return {
      page: 1,
      size: 8,
      loaded: false,
      currentUserParticipateProjectStatus: '', // 当前用户参与项目状态 1 我发布的; 2 我申请的; 3 我竞标的; 4 我承接的
      name: '', // 项目名称
      projectList: [], // 项目数据 list
      total: 0,
      publishInfoList: [
        {
          name: '我发布的',
          val: 1
        },
        {
          name: '我申请的',
          val: 2
        },
        {
          name: '我竞标的',
          val: 3
        },
        {
          name: '我承接的',
          val: 4
        }
      ],
      projectStatusList: [],
      partList: [],
      businessList: [
        {
          name: ''
        },
        {
          name: ''
        },
        {
          name: ''
        },
        {
          name: ''
        }
      ],
      teamworkList: [
        {
          name: '整体外包',
          id: 1
        },
        {
          name: '联合开发',
          id: 2
        }
      ],
      showPublishDialog: false,
      queryParams: {
        statusTypes: '',
        businessTypes: '',
        entrustPartTypes: '',
        cooperationWayTypes: ''
      },
      checking: false,
      hotProject: []
    }
  },
  created() {
    getProjectStatus({
      pageVisible: 1
    }).then(res => {
      const projectStatusList = res.data
      projectStatusList.forEach(item => {
        item.id = item.code
      })
      this.projectStatusList = projectStatusList
    })
    projectEntrustPart().then(res => {
      this.partList = res.data
    })
    getBusinessListApi().then(res => {
      this.businessList = res.data
      this.getProjectList()
      this.getHotProject()
    })
    // this.$nextTick(() => {
    //   this.showPublishDialog = true
    // })
    // 首页跳转，设置默认我的项目
    if (this.$route.query.publishType == 1) {
      this.setPublishType(1)
    }
  },
  watch: {
    queryParams: {
      deep: true,
      handler() {
        if (this.checking) {
          this.checking = false
          return
        }
        this.currentUserParticipateProjectStatus = ''
        this.getProjectList()
      }
    },
    SearchInput(val) {
      this.name = val
      this.currentChange(1)
    },
    ['$route.query']: {
      immediate: true,
      handler() {
        const query = this.$route.query
        this.queryParams.statusTypes = query.status_code ? query.status_code : ''
        this.queryParams.businessTypes = query.business_id ? query.business_id : ''
        this.queryParams.entrustPartTypes = query.entrust_part_id ? query.entrust_part_id : ''
        this.queryParams.cooperationWayTypes = query.cooperation_way ? query.cooperation_way : ''
        log.JSON(this.queryParams)
      }
    }
  },
  computed: {
    ...mapState(['SearchInput'])
  },
  methods: {
    setPriceNum,
    formatTimeFilter,
    handlepart(part) {
      let tag = ''
      this.partList.forEach(res => {
        if (res.id === Number(part)) {
          tag = res.name
        }
      })
      if (!tag) tag = part
      return tag
    },
    computedTime(deadTime) {
      const dead = new Date(deadTime.replace(/-/g, '/')).getTime()
      const now = new Date().getTime()
      return parseInt((dead - now) / 86400000, 10)
    },
    getProjectList() {
      this.loaded = false
      const {businessTypes, statusTypes, entrustPartTypes, cooperationWayTypes} = this.queryParams
      const params = {
        page: this.page,
        size: this.size,
        businessTypes: businessTypes, // 业务类型
        cooperationWayTypes: cooperationWayTypes, // 合作方式
        currentUserParticipateProjectStatus: this.currentUserParticipateProjectStatus, // 用户状态
        entrustPartTypes: entrustPartTypes, // 委托环节
        name: this.name, // 项目名称
        statusTypes: statusTypes // 项目状态
      }
      projectList(params).then(res => {
        this.projectList = res.data ? (res.data.records ? res.data.records : []) : []
        this.total = res.data ? res.data.total : 0
        this.loaded = true
      })
    },
    currentChange(page) {
      this.page = page
      this.getProjectList()
    },
    setStatus(status) {
      return statusList[status]
    },
    getHotProject() {
      getHotProject().then(res => {
        console.log('res ', res)
        this.hotProject = res.data
      })
    },
    resetQueryParams() {
      const queryParams = {
        statusTypes: '',
        businessTypes: '',
        entrustPartTypes: '',
        cooperationWayTypes: ''
      }
      this.$set(this, 'queryParams', queryParams)
    },
    setPublishType(type) {
      if (this.currentUserParticipateProjectStatus === type) {
        this.checking = false
        this.currentUserParticipateProjectStatus = ''
      } else {
        this.checking = true
        this.currentUserParticipateProjectStatus = type
      }
      this.resetQueryParams()
      this.getProjectList()
    },
    goToProject(id) {
      this.$router.push({
        path: '/projectDetail',
        query: {id: id}
      })
    },
    publish() {
      this.showPublishDialog = true
      if (this.currentUserParticipateProjectStatus === 1) {
        this.page = 1
        this.getProjectList()
      }
    },
    publishSuccess() {
      this.$message.success('发布成功')
      this.queryParams = {
        statusTypes: '',
        businessTypes: '',
        entrustPartTypes: '',
        cooperationWayTypes: ''
      }
      this.currentChange(1)
    }
  }
}
</script>

<style lang="scss" scoped>
$radius: 8px;
$fontFamily: PingFangSC-Medium, PingFang SC;
$btnColor: #55cbc4;

.SearchInput {
  display: inline-block;
  margin-left: 160px;
  height: 36px;
  line-height: 36px;
  padding-left: 20px;
  padding-right: 20px;
  background: #5ad3cd;
  color: #fff;
  border-radius: 4px;
  position: relative;
  margin-top: 15px;
  margin-bottom: 15px;

  > i {
    position: absolute;
    color: #666666;
    font-size: 20px;
    right: -30px;
    top: 8px;
  }
}

.content {
  /deep/ .el-dialog {
    border-radius: 10px;
  }
}

.empty {
  width: 600px;
  display: block;
  margin: 80px auto;
}

.selected {
  border: 1px solid #5ad3cd !important;
  color: #5ad3cd !important;
}

.project {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .list {
    width: 890px;
    box-sizing: border-box;
    padding: 20px 30px 20px 120px;
    background: #fff;
    margin-bottom: 16px;
    border-radius: $radius;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    .project-type {
      min-width: 90px;
      height: 40px;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 40px;
      padding: 0 10px;
      text-align: center;
      border-radius: 0 30px 30px 0;
      background-color: #55cbc4;
      position: absolute;
      top: 20px;
      left: 0;
    }

    .project-name {
      width: 560px;
      font-size: 22px;
      font-weight: 500;
      color: #333333;
      line-height: 30px;
      margin-bottom: 10px;
    }

    .project-introduction {
      font-size: 18px;
      font-weight: 400;
      color: #666666;
      line-height: 25px;
      width: 560px;
      margin-bottom: 10px;

      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .company-info {
      position: absolute;
      right: 30px;
      top: 20px;
      text-align: right;

      .budget {
        height: 30px;
        font-size: 22px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 30px;
        margin-bottom: 10px;

        span {
          display: inline-block;
          margin-left: 10px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 30px;
          vertical-align: top;
        }
      }

      .customer-name {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 22px;
        margin-bottom: 6px;

        .location {
          width: 12px;
          display: inline-block;
          margin: 0 0 0 4px;
        }
      }

      .publish-time {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 22px;
      }
    }

    .project-status_info {
      margin-bottom: 20px;

      p {
        height: 26px;
        border: 1px solid #37c2bc;
        border-radius: 20px;
        padding: 0 19px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #37c2bc;
        line-height: 24px;
        margin-right: 12px;
        display: inline-block;
        vertical-align: top;
      }

      span {
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 26px;
        vertical-align: top;
      }
    }

    .project-entrust_part {
      overflow: hidden;

      .part,
      .part-red {
        height: 22px;
        float: left;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        line-height: 22px;
        margin-right: 10px;
        padding: 0 6px;
        border-radius: 2px;
        color: #5ad3cd;
        background-color: rgba(90, 211, 205, 0.1);
      }

      .part-red {
        background-color: rgba(255, 114, 76, 0.1);
        color: rgba(255, 114, 76, 1);
      }
    }
  }

  .left-column {
    width: 290px;

    .publish-options,
    .hot-project {
      background: #fff;
      margin-bottom: 16px;
      box-sizing: border-box;
      border-radius: $radius;
    }

    .hot-project {
      padding: 20px 15px 4px;

      .title {
        font-size: 18px;
        font-family: $fontFamily;
        font-weight: 500;
        color: #333333;
        line-height: 25px;
        margin-bottom: 15px;
      }

      ul {
        padding-top: 15px;
        border-top: 2px solid #dcdfe6;

        li {
          margin-bottom: 16px;

          .project-type {
            width: 20px;
            height: 20px;
            display: inline-block;
            margin-right: 10px;
            background-color: $btnColor;
            line-height: 20px;
            text-align: center;
            font-size: 14px;
            color: #fff;
            vertical-align: top;
          }

          .project-name {
            height: 20px;
            font-size: 14px;
            font-family: $fontFamily;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
            display: inline-block;
            overflow: hidden;
            max-width: 110px;
            white-space: nowrap;
            text-overflow: ellipsis;
            vertical-align: top;
          }

          span {
            height: 16px;
            display: inline-block;
            font-size: 10px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #5ad3cd;
            line-height: 13.5px;
            border: 1px solid $btnColor;
            padding: 0 5px;
            margin-left: 6px;
            margin-top: 2px;
            border-radius: 10px;
            vertical-align: top;
          }

          .project-budget {
            float: right;
            height: 20px;
            font-size: 14px;
            font-family: $fontFamily;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
          }
        }
      }
    }

    .publish-options {
      padding: 30px 15px 20px;
      overflow: hidden;

      .publish-project_btn {
        width: 260px;
        height: 50px;
        font-size: 18px;
        font-weight: 500;
        font-family: $fontFamily;
        color: #ffffff;
        line-height: 50px;
        background-color: $btnColor;
        text-align: center;
        border-radius: $radius;
        margin-bottom: 20px;
        cursor: pointer;
      }

      .publish-type_list {
        width: 123px;
        height: 48px;
        font-size: 18px;
        border: 1px solid #eeeeee;
        color: #999999;
        font-family: $fontFamily;
        font-weight: 500;
        line-height: 48px;
        text-align: center;
        margin-bottom: 20px;
        float: left;
        cursor: pointer;
        border-radius: $radius;

        &:nth-child(2n) {
          margin-right: 10px;
        }
      }

      // .publish-type_list:nth-child(1) {
      //   margin-right: 10px;
      // }
    }
  }
}

.pagination {
  text-align: center;
}

.price-tag {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 25px;
  color: #999999;

  .left {
    color: #999999;
  }

  .mid {
    font-weight: 600;
    color: #ff724c;
  }

  .right {
    color: #999999;
  }
}
</style>
