<template lang="pug">
.set-content
  dialogMask(:show.sync="showDialog", :showClose="true")
    .title
      img(src="../../../../assets/jingbiao2x.png" alt="")
      p 竞标信息
    .content
      .options
        .name 竞标截止日期
        .item
          el-date-picker.picker(
            v-model="form.bidDeadline"
            type="date"
            format="yyyy-MM-dd"
            placeholder="选择日期"
          )
      .options
        .name 项目预算
        .item
          el-input.budget(v-model="form.projectBudget" placeholder="请输入内容")
          el-select.budget-unit(v-model="form.projectBudgetUnit" placeholder="请选择")
            el-option(label="元" value="元")
            el-option(label="万" value="万")
      .options
        .name 期望工期
        .item
          el-select.budget-unit(v-model="form.customerDurationExceptionMonth" placeholder="请选择")
            el-option(label="0个月" value="0")
            el-option(label="1个月" value="1")
            el-option(label="2个月" value="2")
            el-option(label="3个月" value="3")
            el-option(label="4个月" value="4")
            el-option(label="5个月" value="5")
            el-option(label="6个月" value="6")
            el-option(label="7个月" value="7")
            el-option(label="8个月" value="8")
            el-option(label="9个月" value="9")
            el-option(label="10个月" value="10")
            el-option(label="11个月" value="11")
            el-option(label="12个月" value="12")
          el-select.budget-unit(v-model="form.customerDurationExceptionDay" placeholder="请选择")
            el-option(label="0天" value="0")
            el-option(label="1天" value="1")
            el-option(label="2天" value="2")
            el-option(label="3天" value="3")
            el-option(label="4天" value="4")
            el-option(label="5天" value="5")
            el-option(label="6天" value="6")
            el-option(label="7天" value="7")
            el-option(label="8天" value="8")
            el-option(label="9天" value="9")
            el-option(label="10天" value="10")
            el-option(label="11天" value="11")
            el-option(label="12天" value="12")
            el-option(label="13天" value="13")
            el-option(label="14天" value="14")
            el-option(label="14天" value="14")
            el-option(label="15天" value="15")
            el-option(label="16天" value="16")
            el-option(label="17天" value="17")
            el-option(label="18天" value="18")
            el-option(label="19天" value="19")
            el-option(label="20天" value="20")
            el-option(label="21天" value="21")
            el-option(label="22天" value="22")
            el-option(label="23天" value="23")
            el-option(label="24天" value="24")
            el-option(label="25天" value="25")
            el-option(label="26天" value="26")
            el-option(label="27天" value="27")
            el-option(label="28天" value="28")
            el-option(label="29天" value="29")
            el-option(label="30天" value="30")
      .options
        .name 其它备注
        .item
          el-input.textarea(
            type="textarea"
            placeholder="请输入内容"
            v-model="form.customerRemark"
          )
    .submit(@click="submit") 设置
</template>

<script>
import dialogMask from '@/components/common/dialog.vue'
import {setProjectConfig} from '@/api/project'
export default {
  name: 'publishDialog',
  props: ['projectId', 'showSetDialog', 'compInfoms', 'info'],
  data() {
    return {
      showDialog: false,
      form: {
        bidDeadline: '',
        customerDurationExceptionDay: '',
        customerDurationExceptionMonth: '',
        customerRemark: '',
        projectBudget: 0,
        projectBudgetUnit: '元',
        projectId: 0
      }
    }
  },
  watch: {
    showSetDialog(val) {
      if (val) this.showDialog = true
    },
    showDialog(val) {
      if (!val) {
        this.$emit('update:showSetDialog', false)
        this.reset()
      }
    },
    info(val) {
      this.form = {
        bidDeadline: val.bidDeadline.replace(/-/g, '/'),
        customerDurationExceptionDay: val.customerDurationExceptionDay
          ? val.customerDurationExceptionDay.toString()
          : 0,
        customerDurationExceptionMonth: val.customerDurationExceptionMonth
          ? val.customerDurationExceptionMonth.toString()
          : 0,
        customerRemark: val.customerRemark,
        projectBudget: val.projectBudget,
        projectBudgetUnit: val.projectBudgetUnit,
        projectId: val.projectId
      }
      if (this.form.projectBudget < 10000) {
        this.form.projectBudgetUnit = '元'
      } else {
        this.form.projectBudget = this.form.projectBudget / 10000
        this.form.projectBudgetUnit = '万'
      }
    },
    compInfoms() {
      const projectInfo = this.projectInfo
      this.form = {
        hotProjectNumber: projectInfo.hotProjectNumber || 0,
        phone: projectInfo.phone,
        projectId: projectInfo.id,
        projectStatus: projectInfo.status
      }
      // getProjectStatusList().then(res => {
      //   console.log('res ', res)
      //   this.statusList = res.data
      // })
    }
  },
  // created() {
  //   this.showDialog = false
  // },
  components: {
    dialogMask
  },
  methods: {
    reset() {
      this.form = {
        bidDeadline: this.info.bidDeadline.replace(/-/g, '/'),
        customerDurationExceptionDay: this.info.customerDurationExceptionDay.toString(),
        customerDurationExceptionMonth: this.info.customerDurationExceptionMonth.toString(),
        customerRemark: this.info.customerRemark,
        projectBudget: this.info.projectBudget,
        projectBudgetUnit: this.info.projectBudgetUnit,
        projectId: this.info.projectId
      }
      if (this.form.projectBudget < 10000) {
        this.form.projectBudgetUnit = '元'
      } else {
        this.form.projectBudget = this.form.projectBudget / 10000
        this.form.projectBudgetUnit = '万'
      }
    },
    verify() {
      let message = ''
      const projectBudget = Number(this.form.projectBudgetUnit * (this.form.projectBudgetUnit === '元' ? 1 : 10000))
      if (projectBudget < 0 || projectBudget > 1000000000) {
        message = '项目预算为大于0小于等于1亿的正整数。'
      }
      if (!this.form.bidDeadline) {
        message = '截止日期必选'
      }
      if (this.form.customerRemark.length > 200) {
        message = '其它备注限200字'
      }
      if (message) {
        this.$message.error(message)
        return false
      }
      return true
    },
    submit() {
      if (!this.verify()) return
      setProjectConfig(
        Object.assign(this.form, {
          projectId: this.projectId
        })
      ).then(res => {
        this.$emit('uploadSuccess')
        this.$message.success('设置成功')
        this.showDialog = false
        console.log('setProjectConfig ', res)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.set-content {
  user-select: none;
  .title {
    padding-left: 20px;
    margin-bottom: 31px;
    img {
      width: 31px;
      height: 30px;
      margin-top: 2px;
      margin-right: 10px;
      display: inline-block;
      font-size: 0;
    }
    p {
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 33px;
      display: inline-block;
      vertical-align: top;
    }
  }
  .options {
    margin-bottom: 50px;
    position: relative;
    .name {
      width: 120px;
      height: 50px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 50px;
      text-align: right;
      position: absolute;
      left: 0;
      top: 0;
    }
    .item {
      height: 50px;
      line-height: 50px;
      margin-left: 140px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      position: relative;
      .budget {
        width: 200px;
        margin-right: 20px;
      }
      .picker {
        width: 440px;
      }
      span {
        display: inline-block;
        margin-left: 20px;
      }
      .file-name {
        padding: 0 10px;
        width: 330px;
        height: 50px;
        border-radius: 4px;
        border: 1px solid #dedede;
        display: inline-block;
        margin-right: 10px;
      }
      .add-file {
        width: 100px;
        height: 50px;
        background: #f1f1f1;
        border-radius: 4px;
        font-size: 40px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 50px;
        text-align: center;
        display: inline-block;
        vertical-align: top;
        cursor: pointer;
        input {
          display: block;
          width: 440px;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: 0;
          cursor: pointer;
          z-index: 1;
        }
        .over {
          cursor: pointer;
          pointer-events: none;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: 0;
          z-index: 2;
        }
      }
    }
  }
  .content {
    padding: 0 0px 0 86px;
  }
  .textarea {
    height: 180px;
  }
  .submit {
    background-color: rgb(133, 253, 247);
    width: 446px;
    height: 46px;
    background: #5ad3cd;
    border-radius: 23px;
    margin: 200px 134px 0 220px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 46px;
    text-align: center;
    cursor: pointer;
  }
}
</style>
<style lang="scss">
.budget-unit .el-input {
  width: 200px;
  margin-right: 20px;
}
.set-content {
  width: 200px;
  margin-right: 30px;
  /deep/ .el-input {
    width: 200px;
  }
}
/deep/ .el-input__inner {
  width: 200px;
}
</style>
