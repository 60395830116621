<template lang="pug">
.set-content
  dialogMask(:show.sync="showDialog", :showClose="true")
    .title
      img(src="../../../assets/wps.png" alt="")
      p 上传文档
    .content
      .options(v-if="jurisdictionCode.includes(0) || jurisdictionCode.includes(1)")
        .name 文档类型
        .item
          el-select(v-model="form.documentType" placeholder="请选择")
            el-option(
              v-for="item in fileTypeList"
              :key="item.documentType"
              :label="item.name"
              :value="item.documentType"
              v-show="!(jurisdictionCode.includes(0) && item.documentType === 2)"
            )
      .options(v-if="(jurisdictionCode.includes(0) || jurisdictionCode.includes(1)) && form.documentType === 1")
        .name 完善程度
        .item
          el-switch(
            v-model="form.finalVersion"
          )
          span {{form.finalVersion ? '是终稿，需求都已经完整和准确' : '非终稿，还需要继续调整和补充'}}
      .options
        .name 选择文件
        .item
          .file-name {{fileName}}
          .add-file ···
            input(type="file" :accept="accept" @change="handleFileChange")
            .over
    .submit(@click="submit") 上传
</template>

<script>
import dialogMask from '@/components/common/dialog.vue'
import {updateWord, checkWord} from '@/api/project'
// import {verifyPhone} from '@/utils/index'
export default {
  name: 'publishDialog',
  props: ['projectId', 'showWordDialog', 'projectInfo', 'jurisdictionCode'],
  data() {
    return {
      showDialog: false,
      form: {
        projectId: this.projectId, // 项目id
        finalVersion: false, // 完善程度
        documentType: '', // 文档类型
        file: '' // 文件
      },
      fileTypeList: [
        {
          documentType: 1,
          name: '需求文档'
        },
        {
          documentType: 2,
          name: '项目标准'
        },
        {
          documentType: 3,
          name: '其他文档'
        }
      ],
      statusList: [],
      accept:
        'image/jpg,image/jpeg,image/gif,image/png,application/msword,.docx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/plain,application/rtf,application/pdf,.rar,application/zip,.7z',
      fileName: ''
    }
  },
  watch: {
    showWordDialog(val) {
      // eslint-disable-next-line no-debugger
      if (val) this.showDialog = val
    },
    showDialog(val) {
      if (!val) {
        this.$emit('update:showWordDialog', false)
        this.reset()
      }
    },
    projectInfo() {
      const projectInfo = this.projectInfo
      this.form = {
        hotProjectNumber: projectInfo.hotProjectNumber || 0,
        phone: projectInfo.phone,
        projectId: projectInfo.id,
        projectStatus: projectInfo.status
      }
      // getProjectStatusList().then(res => {
      //   console.log('res ', res)
      //   this.statusList = res.data
      // })
    }
  },
  components: {
    dialogMask
  },
  methods: {
    handleFileChange(e) {
      const files = e.target.files
      if (!files.length) return
      const file = files[0]
      this.form.file = file
      this.fileName = file.name
    },
    reset() {
      this.form = {
        projectId: this.projectId, // 项目id
        finalVersion: false, // 完善程度
        documentType: '', // 文档类型
        file: '' // 文件
      }
      this.fileName = ''
    },
    verify(fn) {
      this.$confirm('文档名称和格式重复，确认覆盖么？')
        .then(() => {
          fn()
        })
        .catch(() => {
          this.showDialog = false
        })
    },
    submit() {
      const {file, projectId, documentType} = this.form
      if (!file) {
        this.$message.error('请选择文件')
        return
      }
      if ((this.jurisdictionCode.includes(0) || this.jurisdictionCode.includes(1)) && !documentType) {
        this.$message.error('请选择文档类型')
        return
      }
      const checkParams = new FormData()
      checkParams.append('fileName', file.name)
      checkParams.append('projectId', projectId)
      // checkParams.append('finalVersion', finalVersion)
      checkParams.append('documentType', documentType)
      checkWord(checkParams).then(res => {
        if (res.code === 100001) {
          this.verify(this.submitFile)
        } else if (res.code === 200033) {
          this.$message.error('与其他人上传的文件名重复，请修改文件名')
        } else {
          this.submitFile()
        }
      })
    },
    submitFile() {
      const {file, projectId, finalVersion, documentType} = this.form
      const params = new FormData()

      const loading = this.$loading({
        lock: true,
        text: '上传中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      params.append('file', file)
      params.append('projectId', projectId)
      params.append('finalVersion', finalVersion)
      params.append('documentType', documentType)
      updateWord(params).then(res => {
        loading.close()
        if (res.code === 200) {
          this.showDialog = false
          this.$emit('uploadSuccess', '上传成功')
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.set-content {
  user-select: none;
  .title {
    padding-left: 20px;
    margin-bottom: 31px;
    img {
      width: 31px;
      height: 30px;
      margin-top: 2px;
      margin-right: 10px;
      display: inline-block;
      font-size: 0;
    }
    p {
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 33px;
      display: inline-block;
      vertical-align: top;
    }
  }
  .options {
    margin-bottom: 20px;
    position: relative;
    .name {
      width: 80;
      height: 50px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 50px;
      position: absolute;
      left: 0;
      top: 0;
    }
    .item {
      height: 50px;
      line-height: 50px;
      margin-left: 100px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      position: relative;
      span {
        display: inline-block;
        margin-left: 20px;
      }
      .file-name {
        padding: 0 10px;
        width: 325px;
        height: 50px;
        border-radius: 4px;
        border: 1px solid #dedede;
        display: inline-block;
        margin-right: 10px;
      }
      .add-file {
        width: 100px;
        height: 50px;
        background: #f1f1f1;
        border-radius: 4px;
        font-size: 40px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 50px;
        text-align: center;
        display: inline-block;
        vertical-align: top;
        position: absolute;
        right: 0;
        cursor: pointer;
        input {
          display: block;
          width: 440px;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          opacity: 0;
          cursor: pointer;
          z-index: 1;
        }
        .over {
          cursor: pointer;
          pointer-events: none;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: 0;
          z-index: 2;
        }
      }
    }
  }
  .content {
    padding: 0 138px 0 120px;
  }
  .submit {
    background-color: rgb(133, 253, 247);
    width: 446px;
    height: 46px;
    background: #5ad3cd;
    border-radius: 23px;
    margin: 40px auto 0;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 46px;
    text-align: center;
    cursor: pointer;
  }
}
</style>
<style lang="scss">
.budget-unit .el-input {
  width: 200px;
}
</style>
