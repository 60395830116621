<template lang="pug">
.set-content
  dialogMask(:show.sync="showDialog", :showClose="true")
    .title
      img(src="../../../assets/项目成员@2x.png" alt="")
      .title-desc 项目成员
    .content-list(v-if="memberList && memberList.length > 0")
      ul
        li(v-for="item in memberList")
          span.name {{item.nickName}}
          span.position {{item.occupationName}}
          span.years {{item.workYears}} 年
          span.project-director(v-show="item.projectDirector") 项目主管
          span.project-director-empty(v-show="!item.projectDirector")
          img.del(src="./../../../assets/delete.png", v-if="item.hasDeleteAuth" @click="del(item.id)")
          .del(v-if="!item.hasDeleteAuth")
    .content-add
      .options
        .name 成员昵称
        .item
          el-input.common-width(v-model="form.nickName" placeholder="请输入内容")
      .options
        .name 项目主管
        .item
          el-switch.switch(
            v-model="form.projectDirector"
          )
          span {{form.projectDirector ? '是' : '否'}}
    .add(@click="add") 增加成员
</template>

<script>
import dialogMask from '@/components/common/dialog.vue'
import {delProjectMember, addProjectMember, getMemberList} from '@/api/project'
import {getEmployeeOccupationListApi} from '@/api/index'

export default {
  name: 'addMember',
  props: ['showMemberDialog', 'projectId', 'tendererId'],
  data() {
    return {
      showDialog: false,
      memberList: [],
      form: {
        nickName: '',
        projectDirector: false
      },
      occupationList: ''
    }
  },
  watch: {
    showMemberDialog(val) {
      if (val) this.showDialog = true
    },
    showDialog(val) {
      if (!val) {
        this.$emit('update:showMemberDialog', false)
      } else {
        this.init()
      }
    }
  },
  components: {
    dialogMask
  },
  methods: {
    init() {
      getEmployeeOccupationListApi().then(res => {
        this.occupationList = res.data
        const params = {
          projectId: this.projectId
        }
        getMemberList(params)
          .then(item => {
            this.memberList = item.data || []
            this.memberList.forEach(element => {
              this.occupationList.forEach(itm => {
                if (element.occupation === itm.id) {
                  element.occupationName = itm.name
                }
              })
            })
          })
          .catch(err => err)
      })
    },
    add() {
      addProjectMember(
        Object.assign(this.form, {
          projectId: this.projectId
        })
      ).then(res => {
        if (res.code === 200) {
          this.$message.success('添加成功')
          this.init()
          this.$emit('uploadSuccess')
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    del(id) {
      this.$alert('', '确定删除么？', {
        confirmButtonText: '确定',
        callback: action => {
          if (action === 'confirm') {
            delProjectMember(
              Object.assign(
                {},
                {
                  projectId: this.projectId,
                  id: id
                }
              )
            ).then(res => {
              if (res.code === 200) {
                this.$message.success('删除成功')
                this.$emit('uploadSuccess')
                this.init()
              } else {
                this.$message.error(res.msg)
              }
            })
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.set-content {
  user-select: none;
  .title {
    padding-left: 20px;
    margin-bottom: 40px;
    img {
      width: 32px;
      margin-top: 2px;
      margin-right: 10px;
      display: inline-block;
      font-size: 0;
    }
    .title-desc {
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 33px;
      display: inline-block;
      vertical-align: top;
    }
  }
  .add {
    width: 446px;
    height: 46px;
    background: #5ad3cd;
    border-radius: 23px;
    text-align: center;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 46px;
    margin: 0 auto;
  }
  .options {
    margin-bottom: 20px;
    position: relative;
    .name {
      width: 120px;
      height: 50px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 50px;
      text-align: left;
      position: absolute;
      left: 0;
      top: 0;
    }
    .item {
      height: 50px;
      line-height: 50px;
      margin-left: 110px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      position: relative;
    }
  }
  .content-list {
    margin: 0 auto 60px;
    border-bottom: 1px solid #dedede;
    overflow: hidden;
    li {
      height: 30px;
      overflow: hidden;
      margin-bottom: 33px;
      text-align: center;
      span {
        vertical-align: top;
        height: 30px;
        font-size: 22px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 30px;
        display: inline-block;
      }
      .name {
        width: 180px;
        margin-right: 20px;
      }
      .years {
        width: 80px;
        margin-right: 20px;
      }
      .position {
        width: 180px;
        margin-right: 20px;
      }
      .project-director {
        width: 90px;
        height: 30px;
        background: #5ad3cd;
        border-radius: 4px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 30px;
        text-align: center;
        vertical-align: top;
        padding: 0 0 0 0;
        margin-right: 40px;
      }
      .project-director-empty {
        width: 90px;
        height: 30px;
        border-radius: 4px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 30px;
        text-align: center;
        vertical-align: top;
        padding: 0 0 0 0;
        margin-right: 40px;
      }
      .del {
        vertical-align: top;
        margin: 5px 0 0 0;
        width: 20px;
        height: 20px;
        cursor: pointer;
        display: inline-block;
      }
    }
  }
  .content-add {
    margin-left: 130px;
    .options {
      margin-bottom: 20px;
      span {
        margin-left: 10px;
        vertical-align: top;
        display: inline-block;
      }
    }
    .common-width {
      width: 200px;
      margin-right: 30px;
    }
  }
  .submit {
    background-color: rgb(133, 253, 247);
    width: 446px;
    height: 46px;
    background: #5ad3cd;
    border-radius: 23px;
    margin: 60px auto 0;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 46px;
    text-align: center;
    cursor: pointer;
  }
}
</style>
<style lang="scss">
.common-width .el-input {
  width: 220px;
}
</style>
