<template lang="pug">
  .set-content
    dialogMask(:show.sync="dialogVisible", :showClose="true")
      .title
        img(src="./../../assets/projectSet.png")
        p 项目设置
      .content
        .options
          .name 热门项目序号
          .item
            el-select(v-model="form.hotProjectNumber" placeholder="请选择")
              el-option(
                key="0"
                label="0"
                value="0"
              )
              el-option(
                v-for="item in 20"
                :key="item"
                :label="item"
                :value="item"
              )
        .options
          .name 所属用户
          .item
            el-input(v-model="form.phone" placeholder="请输入手机号")
        .options
          .name 项目状态
          .item
            el-select(v-model="form.projectStatus" placeholder="请选择")
              el-option(
                v-for="item in statusList"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              )
      .submit(@click="submit") 设置

</template>

<script>
import dialogMask from '@/components/common/dialog.vue'
import {setProject, getProjectStatusList} from '@/api/project'
// import {verifyPhone} from '@/utils/index'
export default {
  name: 'publishDialog',
  props: ['projectId', 'showProjectSetDialog', 'projectInfo'],
  data() {
    return {
      dialogVisible: false,
      form: {
        hotProjectNumber: '',
        phone: '',
        projectId: '',
        projectStatus: ''
      },
      statusList: []
    }
  },
  created() {
    const projectInfo = this.projectInfo
    this.form = {
      hotProjectNumber: projectInfo.hotNum || '',
      phone: projectInfo.userPhoneNumber,
      projectId: projectInfo.id,
      projectStatus: projectInfo.status
    }
    getProjectStatusList().then(res => {
      this.statusList = res.data
    })
  },
  watch: {
    showProjectSetDialog(val) {
      if (val) this.dialogVisible = val
    },
    dialogVisible(val) {
      if (!val) {
        this.$emit('update:showProjectSetDialog', false)
      }
    },
    projectInfo() {
      const projectInfo = this.projectInfo
      this.form = {
        hotProjectNumber: projectInfo.hotNum,
        phone: projectInfo.userPhoneNumber,
        projectId: projectInfo.id,
        projectStatus: projectInfo.status
      }
      getProjectStatusList().then(res => {
        console.log('res ', res)
        this.statusList = res.data
      })
    }
  },
  components: {
    dialogMask
  },
  methods: {
    verify() {},
    submit() {
      const params = Object.assign({}, JSON.parse(JSON.stringify(this.form)), {
        projectId: this.projectId,
        hotProjectNumber: Number(this.form.hotProjectNumber)
      })
      setProject(params).then(res => {
        if (res.code === 200) {
          this.dialogVisible = false
          this.$emit('setSuccess', '设置成功')
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.set-content {
  user-select: none;
  .title {
    padding-left: 20px;
    margin-bottom: 31px;
    img {
      width: 31px;
      height: 30px;
      margin-top: 2px;
      margin-right: 10px;
      display: inline-block;
      font-size: 0;
    }
    p {
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 33px;
      display: inline-block;
      vertical-align: top;
    }
  }
  .options {
    margin-bottom: 20px;
    position: relative;
    .name {
      width: 120px;
      height: 50px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 50px;
      position: absolute;
      left: 0;
      top: 0;
    }
    .item {
      margin-left: 140px;
    }
  }
  .content {
    padding: 0 138px 0 120px;
  }
  .submit {
    background-color: rgb(133, 253, 247);
    width: 446px;
    height: 46px;
    background: #5ad3cd;
    border-radius: 23px;
    margin: 40px auto 0;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 46px;
    text-align: center;
    cursor: pointer;
  }
}
</style>
<style lang="scss">
.budget-unit .el-input {
  width: 200px;
}
</style>
