/* eslint-disable no-debugger */
<template lang="pug">
.comment-content
  dialogMask(:show.sync="showDialog", :showClose="true")
    .title
      img(src="../../../assets/comment.png" alt="")
      .title-desc 评价
      //- img(src="../../../assets/projectPlan.png" alt="")
      .comment-detail
        .option
          p 评价
          el-select.select(v-model="win" value-key="id")
            el-option(
              v-for="item in winList"
              :key="item.id"
              :label="item.name"
              :value="item"
            )
        .option
          p 满意度
          img(v-for="i in 5" :key="i"  :src="form.score > (i - 1) ? starHeight : star" alt="" @click="setScore(i)")
          .satisfaction(v-if="form.score === 5") 非常满意
          .satisfaction(v-if="form.score === 4") 满意
          .satisfaction(v-if="form.score === 3") 一般般
          .satisfaction(v-if="form.score === 2") 不满意
          .satisfaction(v-if="form.score === 1") 非常不满意
        .option
          p 评价描述
          el-input.select.textarea(type="textarea" v-model="form.evaluation" resize="none" maxlength="200")
    .submit(@click="submit") 提交
</template>

<script>
import dialogMask from '@/components/common/dialog.vue'
import {addComment, getWinList, evaluation} from '../../../api/project'

const starHeight = require('../../../assets/start-comment.png')
const star = require('../../../assets/comment.png')
export default {
  name: 'set-project-plan-dialog',
  props: ['projectId', 'showCommentDialog'],
  components: {
    dialogMask
  },
  data() {
    return {
      showDialog: false,
      winList: [],
      win: '',
      form: {
        evaluation: '',
        score: 0,
        objectId: '',
        objectType: ''
      },
      starHeight,
      star
    }
  },
  watch: {
    showCommentDialog(val) {
      if (val) this.showDialog = true
    },
    showDialog(val) {
      if (!val) {
        this.$emit('update:showCommentDialog', false)
        this.reset()
      }
    },
    win(val) {
      this.form.objectId = val.id || ''
      this.form.objectType = val.type || ''
    }
  },
  created() {
    getWinList({
      projectId: this.projectId
    }).then(res => {
      if (res.code === 200) {
        this.winList = res.data
      }
    })
  },
  methods: {
    reset() {
      this.form = {
        evaluation: '',
        score: 0,
        objectId: '',
        objectType: ''
      }
      this.win = ''
    },
    setScore(index) {
      this.$set(this.form, 'score', index)
    },
    verify() {
      let message
      const {evaluation, score, objectId} = this.form
      if (evaluation === '') {
        message = '请输入评价描述'
      }
      if (score === 0) {
        message = '请选择满意度'
      }
      if (objectId === '') {
        message = '请选择评价方'
      }

      if (message) {
        this.$message({
          type: 'error',
          message: message
        })
        return false
      }
      return true
    },
    submit() {
      if (!this.verify()) return
      const params = {
        ...this.form,
        projectId: this.projectId
      }
      evaluation(params).then(res => {
        if (res.code === 100003) {
          this.$confirm('已评价过，确定更新吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(() => {
              addComment(params).then(res => {
                if (res.code === 200) {
                  this.reset()
                  this.showDialog = false
                  this.$emit('update')
                  this.$message({
                    type: 'success',
                    message: '更新成功'
                  })
                }
              })
            })
            .catch(() => {
              this.$message({
                type: 'info',
                message: '已取消删除'
              })
            })
        } else {
          addComment(params).then(res => {
            if (res.code === 200) {
              this.reset()
              this.showDialog = false
              this.$emit('update')
              this.$message({
                type: 'success',
                message: '评价成功'
              })
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.comment-content {
  user-select: none;
  .title {
    padding: 20px 30px 0 40px;
    img {
      height: 29px;
      width: 30px;
      margin-right: 2px;
      margin-top: 2px;
      margin-bottom: 2px;
      display: inline-block;
      font-size: 0;
    }
    .title-desc {
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 33px;
      display: inline-block;
      vertical-align: top;
      margin-left: 9px;
    }
  }
  .comment-detail {
    padding: 66px 65px 0 55px;
    .option {
      margin-bottom: 34px;
      position: relative;
      line-height: 50px;
      p {
        margin-right: 30px;
        display: inline-block;
        width: 80px;
        text-align: right;
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        vertical-align: top;
      }
      .satisfaction {
        margin-left: 21px;
        display: inline;
        vertical-align: top;
      }
      img {
        width: 20px;
        height: 20px;
        display: inline-block;
        vertical-align: top;
        margin: 15px 7px 4px 0;
      }
      .select {
        display: inline-block;
      }
      .textarea {
        width: 500px;
        height: 200px;
      }
    }
  }
  .submit {
    background-color: rgb(133, 253, 247);
    width: 446px;
    height: 46px;
    background: #5ad3cd;
    border-radius: 23px;
    margin: 0 auto 0;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 46px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 41px;
  }
}
</style>
<style lang="scss">
.option .el-input {
  width: 200px;
}
</style>
