<template lang="pug">
.set-content
  dialogMask(:show.sync="showDialog", :showClose="true")
    .title
      img(src="../../../../assets/document.png" alt="")
      .title-desc 附言
    .content {{postscript}}
</template>

<script>
import dialogMask from '@/components/common/dialog.vue'
export default {
  name: 'apply',
  props: ['postscript', 'showPostscriptDialog'],
  data() {
    return {
      showDialog: false
    }
  },
  watch: {
    showPostscriptDialog(val) {
      if (val) this.showDialog = true
    },
    showDialog(val) {
      if (!val) {
        this.$emit('update:showPostscriptDialog', false)
      }
    }
  },
  components: {
    dialogMask
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.set-content {
  user-select: none;
  .title {
    padding-left: 20px;
    margin-bottom: 40px;
    img {
      width: 29px;
      height: 32px;
      margin-top: 2px;
      margin-right: 10px;
      display: inline-block;
      font-size: 0;
    }
    .title-desc {
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 33px;
      display: inline-block;
      vertical-align: top;
    }
  }
  .content {
    padding: 0 10px 0 70px;
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 28px;
  }
  .textarea {
    height: 180px;
  }
  .submit {
    background-color: rgb(133, 253, 247);
    width: 446px;
    height: 46px;
    background: #5ad3cd;
    border-radius: 23px;
    margin: 60px auto 0;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 46px;
    text-align: center;
    cursor: pointer;
  }
  p {
    width: 428px;
    height: 40px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
    text-align: center;
    margin: 30px auto 10px;
  }
}
</style>
<style lang="scss">
.half-helf {
  width: 100px !important;
  .el-input {
    width: 100px;
  }
}
.right {
  margin-right: 20px;
}
</style>
