<template lang="pug">
.set-content
  dialogMask(:show.sync="showDialog", :showClose="true")
    .title
      img(src="../../../../assets/gujia@2x.png" alt="")
      p 平台估价
    .content
      .options
        .name 分组名称
        .item
          el-input(v-model="form.groupName" placeholder="请输入内容")
      .options
        .name 显示分组名称
        .item
          el-switch(
            v-model="form.groupNameVisible"
          )
      .options
        .name 分组序号
        .item
          el-select.half(v-model="form.groupSequenceNum" placeholder="请选择")
            el-option(
              v-for="(item, index) in 10"
              :key="`${item}evaluate1`"
              :label="item"
              :value="item"
            )
      .options
        .name 估价名称
        .item
          el-select.half(v-model="form.quotationName" placeholder="请选择")
            el-option(
              v-for="(item, index) in list"
              :key="`${item}evaluate3`"
              :label="item"
              :value="item"
            )
      .options
        .name 估价序号
        .item
          el-select.half(v-model="form.quotationSequenceNum" placeholder="请选择")
            el-option(
              v-for="(item, index) in 20"
              :key="`${item}evaluate2`"
              :label="item"
              :value="item"
            )
      .options
        .name 估价
        .item
          el-input.half.right(v-model="form.projectBudget" placeholder="请输入内容")
          el-select.half(v-model="form.projectBudgetUnit" placeholder="请选择")
            el-option(label="元" value="元")
            el-option(label="万" value="万")
    .submit(@click="submit") 设置
</template>

<script>
import dialogMask from '@/components/common/dialog.vue'
import {setPlatformQuotation} from '@/api/project'
export default {
  name: 'evaluate',
  props: ['projectId', 'showEvaluateDialog', 'info'],
  data() {
    return {
      showDialog: false,
      form: {
        groupName: '',
        groupNameVisible: true,
        groupSequenceNum: '',
        projectBudget: '',
        projectBudgetUnit: '',
        projectId: 0,
        quotationName: '',
        quotationSequenceNum: ''
      },
      list: [
        '项目总价',
        '产品部分',
        '视觉部分',
        '前端部分',
        '客户端部分',
        '客户端(IOS）部分',
        '客户端（Android）部分',
        '后端部分',
        '测试部分',
        '运维部分',
        'web端部分',
        'app端部分',
        '小程序端部分'
      ]
    }
  },
  watch: {
    showEvaluateDialog(val) {
      if (val) this.showDialog = true
    },
    showDialog(val) {
      if (!val) {
        this.$emit('update:showEvaluateDialog', false)
        this.reset()
      }
    }
    // info(val) {
    //   this.form = {
    //     bidDeadline: val.bidDeadline,
    //     customerDurationExceptionDay: val.customerDurationExceptionDay,
    //     customerDurationExceptionMonth: val.customerDurationExceptionMonth,
    //     customerRemark: val.customerRemark,
    //     projectBudget: val.projectBudget,
    //     projectBudgetUnit: val.projectBudgetUnit,
    //     projectId: val.projectId
    //   }
    // }
  },
  components: {
    dialogMask
  },
  methods: {
    reset() {
      this.form = {
        groupName: '',
        groupNameVisible: true,
        groupSequenceNum: '',
        projectBudget: '',
        projectBudgetUnit: '',
        projectId: 0,
        quotationName: '',
        quotationSequenceNum: ''
      }
    },
    verify() {
      let message = ''
      if (this.form.projectBudget <= 0) {
        message = '估价需大于0'
      }
      if (message) {
        this.$message.error(message)
        return false
      }
      return true
    },
    submit() {
      if (!this.verify()) return
      setPlatformQuotation(
        Object.assign(this.form, {
          projectId: this.projectId
        })
      ).then(res => {
        if (res.code !== 200) {
          this.$message.error(res.data)
          return
        }
        console.log('res ', res)
        this.$emit('uploadSuccess')
        this.$message.success('设置成功')
        this.showDialog = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.set-content {
  user-select: none;
  .title {
    padding-left: 20px;
    margin-bottom: 31px;
    img {
      width: 30px;
      height: 34px;
      margin-right: 10px;
      display: inline-block;
      font-size: 0;
    }
    p {
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 34px;
      display: inline-block;
      vertical-align: top;
    }
  }
  .options {
    margin-bottom: 50px;
    position: relative;
    .name {
      width: 120px;
      height: 50px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 50px;
      text-align: right;
      position: absolute;
      left: 0;
      top: 0;
    }
    .item {
      height: 50px;
      line-height: 50px;
      margin-left: 140px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      position: relative;
      .budget {
        width: 200px;
        margin-right: 20px;
      }
      .picker {
        width: 440px;
      }
      span {
        display: inline-block;
        margin-left: 20px;
      }
      .file-name {
        padding: 0 10px;
        width: 330px;
        height: 50px;
        border-radius: 4px;
        border: 1px solid #dedede;
        display: inline-block;
        margin-right: 10px;
      }
      .add-file {
        width: 100px;
        height: 50px;
        background: #f1f1f1;
        border-radius: 4px;
        font-size: 40px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 50px;
        text-align: center;
        display: inline-block;
        vertical-align: top;
        cursor: pointer;
        input {
          display: block;
          width: 440px;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: 0;
          cursor: pointer;
          z-index: 1;
        }
        .over {
          cursor: pointer;
          pointer-events: none;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: 0;
          z-index: 2;
        }
      }
    }
  }
  .content {
    padding: 0 134px 0 86px;
  }
  .textarea {
    height: 180px;
  }
  .submit {
    background-color: rgb(133, 253, 247);
    width: 446px;
    height: 46px;
    background: #5ad3cd;
    border-radius: 23px;
    margin: 60px 128px 0 226px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 46px;
    text-align: center;
    cursor: pointer;
  }
}
</style>
<style lang="scss">
.budget-unit .el-input {
  width: 200px;
}
.half {
  width: 200px !important;
  .el-input {
    width: 200px;
  }
}
.right {
  margin-right: 40px;
}
</style>
