<template lang="pug">
.word-detail
  .title
    p.name 项目文档
    .add(@click="addWord", v-if="status !== '已停止' && (jurisdictionCode.includes(1) || jurisdictionCode.includes(0) || jurisdictionCode.includes(2))") 
      img(src="./../../../assets/add_project.png")
      span 添加文档
  el-divider.el-divider
  .project-status
    .project-status-item(v-for="(item, index) in wordList" :key="item.id")
      img.file-type-img(@click="down(item)", :src="fileExtensionName[item.extensionName.toLowerCase()] ? fileExtensionName[item.extensionName.toLowerCase()] : noType")
      el-tooltip(class="item" effect="dark" :content="item.type === 3 ? `${item.name}` : fileTypeList[item.type]" placement="top-start")
        .project-type {{item.type === 3 ? `${item.name}` : fileTypeList[item.type]}}
      .project-time {{item.lastModifyTime}}
      .is-project-delete
        .project-not-end(v-if="!item.isFinalVersion && item.type === 1") 非终稿
        .project-end(v-if="item.isFinalVersion") 终稿
        .project-end-placeholder(v-if="!item.isFinalVersion && item.type !== 1")
        img(src="../../../assets/delete.png" v-if="item.hasAuth" @click="handleDeleteWord(item.id, index)")

  addWordDialog(
    :showWordDialog.sync="showDialog" 
    :jurisdictionCode="jurisdictionCode" 
    :projectId="projectId" 
    @uploadSuccess="uploadSuccess"
  )
</template>

<script>
import addWordDialog from './addWordDialog.vue'
import {getWordList, delWordList} from '@/api/project'
const DOCUMENT = require('../../../assets/document.png')
const IMAGE = require('../../../assets/image.png')
const ZIP = require('../../../assets/zip.png')
const NOTYPE = require('../../../assets/no_type.png')

export default {
  name: 'projectWord',
  components: {
    addWordDialog
  },
  props: ['projectId', 'jurisdictionCode', 'status'],
  data() {
    return {
      showDialog: false,
      wordList: [], // 项目状态列表
      fileTypeList: ['', '需求文档', '项目标准', '其他文档'],
      fileExtensionName: {
        jpg: IMAGE,
        jpeg: IMAGE,
        gif: IMAGE,
        png: IMAGE,
        doc: DOCUMENT,
        docx: DOCUMENT,
        xls: DOCUMENT,
        xlsx: DOCUMENT,
        txt: DOCUMENT,
        rtf: DOCUMENT,
        pdf: DOCUMENT,
        rar: ZIP,
        zip: ZIP,
        '7z': ZIP
      }, //根据文件扩展名寻找对应的图片
      noType: NOTYPE
    }
  },
  created() {
    this.getWordList()
  },
  methods: {
    addWord() {
      this.showDialog = true
    },
    uploadSuccess() {
      this.showDialog = false
      this.getWordList()
    },
    // 获取文档列表
    getWordList() {
      getWordList({
        projectId: this.projectId
      }).then(res => {
        const wordList = res.data.length > 0 ? res.data : []
        console.log('wordList=====', wordList)
        wordList.forEach(item => {
          const date = new Date(item.lastModifyTime.replace(/-/g, '/'))
          const month = date.getMonth() + 1
          const day = date.getDate()
          const hour = date.getHours()
          const minute = date.getMinutes()
          item.lastModifyTime = `${month}.${day} ${hour > 10 ? hour : `0${hour}`}:${
            minute > 10 ? minute : `0${minute}`
          }`
        })
        this.wordList = wordList
      })
    },
    down(info) {
      const {url, name, hasViewAuth, notAuthMsg} = info
      if (hasViewAuth) {
        const eLink = document.createElement('a')
        eLink.href = url
        eLink.setAttribute('download', name)
        eLink.style.display = 'none'
        document.body.appendChild(eLink)
        setTimeout(() => {
          eLink.click()
          document.body.removeChild(eLink)
        }, 66)
      } else {
        this.$message.error(notAuthMsg)
      }
    },
    // 删除文档
    handleDeleteWord(id, index) {
      // eslint-disable-next-line no-debugger
      const params = {
        id: id
      }
      delWordList(params).then(res => {
        console.log('1111 ', res)
        this.wordList.splice(index, 1)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$radius: 8px;
$fontFamily: PingFangSC-Medium, PingFang SC;
$btnColor: #55cbc4;
.word-detail {
  background: #ffffff;
  border-radius: 8px;
  padding: 27px 80px 40px 80px;
  position: relative;
  vertical-align: top;
  .title {
    overflow: hidden;
    .name {
      height: 30px;
      font-size: 22px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 30px;
      float: left;
    }
    .add {
      width: 120px;
      height: 40px;
      background: #5ad3cd;
      border-radius: 2px;
      text-align: center;
      float: right;
      font-size: 16px;
      line-height: 40px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      cursor: pointer;
      img {
        width: 20px;
        height: 20px;
        display: inline-block;
        margin: 10px 6px 0 0;
        line-height: 40px;
        font-size: 0;
      }
      span {
        vertical-align: top;
      }
    }
  }
  .el-divider {
    margin: 18px 0 30px 0;
  }
  .project-status {
    padding: 0 16px;
    .project-status-item {
      display: inline-block;
      vertical-align: top;
      text-align: center;
      margin: 0 80px 23px 0;
      &:nth-child(1) {
        margin-left: 0;
      }
      .file-type-img {
        width: 30px;
        height: 37px;
      }
      .project-type {
        width: 52px;
        height: 18px;
        font-size: 13px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 18px;
        margin: 11px 3px 6px 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .project-time {
        width: 59px;
        height: 17px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 17px;
      }
      .is-project-delete {
        margin-top: 10px;
        font-size: 0;
        .project-not-end {
          width: 50px;
          height: 18px;
          line-height: 18px;
          background: #ff724c;
          border-radius: 2px;
          font-size: 10px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          margin: 0 4px 0 5px;
        }
        .project-end {
          width: 50px;
          height: 18px;
          line-height: 18px;
          background: #5ad3cd;
          border-radius: 2px;
          font-size: 10px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          margin: 0 4px 0 5px;
        }
        .project-end-placeholder {
          width: 50px;
          height: 18px;
          line-height: 18px;
          border-radius: 2px;
          font-size: 10px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          margin: 0 4px 0 5px;
        }
        img {
          width: 20px;
          height: 20px;
          cursor: pointer;
          margin-top: 8px;
        }
      }
    }
    .project-status-item:nth-child(1) {
      padding-left: 0;
    }
  }
}
</style>
