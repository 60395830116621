<template lang="pug">
.project-comp-info 
  .project-comp-info-title 项目竞标信息
  el-divider.el-divider
  comp-info(
    ref="compInfo"
    :projectId="projectId", 
    :jurisdictionCode="jurisdictionCode"
    :status="status"
    @uploadSuccess="uploadSuccess"
    @uploadAuth="uploadAuth"
  )
  bid-demand(
    ref="bidDemand"
    :projectId="projectId", 
    :status="status"
    :jurisdictionCode="jurisdictionCode", 
    @uploadSuccess="uploadSuccess"
  )
  bid-customer(
    ref="bidCustomer"
    :projectId="projectId",
    :finalVersion="finalVersion"
    :jurisdictionCode="jurisdictionCode",
    :status="status"
    @uploadSuccess="uploadSuccess"
  )
  bidApply(
    ref="bidApply"
    :status="status"
    :projectId="projectId", 
    :jurisdictionCode="jurisdictionCode",
    @uploadSuccess="uploadSuccess"
    @uploadAuth="uploadAuth"
  )
</template>

<script>
import bidApply from './bidApply.vue'
import compInfo from './compInfo.vue'
import bidDemand from './bidDemand.vue'
import bidCustomer from './bidCustomer.vue'
export default {
  name: 'project-comp-info',
  components: {compInfo, bidDemand, bidCustomer, bidApply},
  props: ['projectId', 'jurisdictionCode', 'status', 'finalVersion'],
  data() {
    return {
      info: [],
      compInfoms: []
    }
  },
  methods: {
    uploadSuccess() {
      this.$refs.compInfo.init()
      this.$refs.bidDemand.init()
      this.$refs.bidCustomer.init()
      this.$refs.bidApply.init()
    },
    uploadAuth() {
      this.$emit('uploadAuth')
    }
  }
}
</script>

<style lang="scss" scoped>
.project-comp-info {
  background-color: #fff;
  border-radius: 8px;
  padding: 40px 80px 40px 80px;
  position: relative;
  margin-bottom: 20px;
  margin-top: 20px;

  .project-comp-info-title {
    height: 30px;
    font-size: 22px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 30px;
  }
  .el-divider {
    margin: 30px 0 30px 0;
  }
}
</style>
