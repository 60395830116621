<template lang="pug">
.set-content
  dialogMask(:show.sync="showDialog", :showClose="true")
    .title
      img(src="../../../../assets/987.png" alt="")
      .title-desc 申请投标
    ul
      li(v-for="(item, index) in list" :key="`${item}allApply`")
        img.icon(:src="item.logoPath" @click="handelToDeatil(item.tendererType, item.tendererId)")
        .info
          .name(@click="handelToDeatil(item.tendererType, item.tendererId)") {{item.tendererName}}
            .prict {{resetBudget(item.quotationMin)}} - {{resetBudget(item.quotationMax)}}
          .desc 
            span {{item.demandSatisfied ? '满足投标要求' : '不满足投标要求'}}
            span {{item.tenderTime}}
        .btn-list
          .consent(@click="consent(item)" v-if="statusList[status] !== '已停止' && (jurisdictionCode.includes(0) || jurisdictionCode.includes(1))") 同意
          .withdraw(@click="withdraw(item)" v-if="statusList[status] !== '已停止' && item.hasWithdrawAuth") 撤回
</template>

<script>
import dialogMask from '@/components/common/dialog.vue'
import {statusList} from '@/config/status.js'

export default {
  name: 'apply',
  props: ['showApplyDialog', 'list', 'jurisdictionCode', 'status'],
  data() {
    return {
      showDialog: false,
      statusList
    }
  },
  watch: {
    showApplyDialog(val) {
      if (val) this.showDialog = true
    },
    showDialog(val) {
      if (!val) {
        this.$emit('update:showApplyDialog', false)
      }
    }
  },
  components: {
    dialogMask
  },
  methods: {
    handelToDeatil(type, id) {
      if (type === 1) {
        this.$router.push({
          path: `/companyDetail/${id}`
        })
      } else {
        this.$router.push({
          path: `/teams/detail/${id}`
        })
      }
    },
    resetBudget(budget) {
      if (budget === '') return '未知'
      if (budget < 10000) return `${budget}元`
      return `${budget / 10000}万`
    },
    consent(info) {
      this.$parent.consent(info)
      this.showDialog = false
    },
    withdraw(info) {
      this.$parent.withdraw(info)
      this.showDialog = false
    }
  }
}
</script>

<style lang="scss" scoped>
.set-content {
  user-select: none;
  .title {
    padding-left: 20px;
    margin-bottom: 31px;
    img {
      width: 29px;
      height: 32px;
      margin-top: 2px;
      margin-right: 10px;
      display: inline-block;
      font-size: 0;
    }
    .title-desc {
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 33px;
      display: inline-block;
      vertical-align: top;
    }
  }
  ul {
    padding: 0 40px 20px;
    li {
      margin-bottom: 40px;
      vertical-align: top;
      img {
        height: 80px;
        width: 80px;
        display: inline-block;
        margin-right: 20px;
        vertical-align: top;
      }
      .info {
        overflow: hidden;
        display: inline-block;
      }
      .name {
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 30px;
        margin-right: 42px;
        vertical-align: top;
      }
      .prict {
        margin-left: 20px;
        display: inline-block;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 30px;
        vertical-align: top;
      }
      .desc {
        display: inline-block;
        margin-top: 14px;
        span {
          height: 34px;
          display: inline-block;
          padding: 0 5px;
          margin-right: 30px;
          background: #f1f1f1;
          border-radius: 2px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 34px;
        }
      }
      .btn-list {
        float: right;
        margin-top: 23px;
        vertical-align: top;

        .consent {
          width: 80px;
          height: 34px;
          background: #5ad3cd;
          border-radius: 4px;
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 34px;
          text-align: center;
          margin-right: 10px;
          float: left;
          cursor: pointer;
        }
        .withdraw {
          width: 80px;
          height: 34px;
          background: #ff724c;
          border-radius: 4px;
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 34px;
          text-align: center;
          float: left;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.half-helf {
  width: 100px !important;
  .el-input {
    width: 100px;
  }
}
.right {
  margin-right: 20px;
}
</style>
