<template lang="pug">
.comp-info-box
  .comp-info-container
    .comp-info-point
    .comp-info-title 投标申请
  ul
    li(v-for="(item, index) in list" v-if="index < 3" :key="`${index}bidApply`")
      img.icon(:src="item.logoPath" @click="handelToDeatil(item.tendererType, item.tendererId)")
      .name(@click="handelToDeatil(item.tendererType, item.tendererId)") {{item.tendererName}}
      .prict {{resetBudget(item.quotationMin)}} - {{resetBudget(item.quotationMax)}}
      .desc 
        span {{item.demandSatisfied ? '满足投标要求' : '不满足投标要求'}}
        span {{item.tenderTime}}
      .btn-list
        .consent(@click="consent(item)" v-if="statusList[status] !== '已停止' && (jurisdictionCode.includes(0) || jurisdictionCode.includes(1))") 同意
        .withdraw(@click="withdraw(item)" v-if="statusList[status] !== '已停止' && item.hasWithdrawAuth") 撤回
  el-button.apply(
    v-if="![930, 940, 240].includes(status) && !jurisdictionCode.includes(2) && !jurisdictionCode.includes(3)"
    @click="handleSetBidDemand" 
    type="primary" 
  ) 申请投标
  el-button.check-all(
    @click="checkAll" 
    v-if="list.length > 3"
    type="primary" 
  ) 查看全部
  apply(
    :projectId="projectId",
    :showApplyDialog.sync="showDialog",
    @uploadSuccess="uploadSuccess",
  )
  allApply(
    :showApplyDialog.sync="showAllApply",
    :jurisdictionCode="jurisdictionCode"
    :status="status"
    :list="list"
    @uploadSuccess="uploadSuccess",
  )
</template>

<script>
import {checkTenderList, removeApply, passApply} from '@/api/project'
import apply from '@/components/project/projectCompInfo/dialog/apply.vue'
import allApply from '@/components/project/projectCompInfo/dialog/allApply.vue'
import {statusList, applyStatus} from '@/config/status.js'
export default {
  name: 'bidDemand',
  props: ['projectId', 'jurisdictionCode', 'status'],
  data() {
    return {
      list: [],
      statusList,
      applyStatus,
      showDialog: false,
      showAllApply: false
    }
  },
  components: {
    apply,
    allApply
  },
  created() {
    this.init()
  },
  methods: {
    consent(info) {
      passApply({
        applyId: info.id,
        projectId: this.projectId
      }).then(res => {
        if (res.code === 200) {
          if (this.list.length === 1) {
            this.showDialog = false
          }
          this.init()
          this.$message.success(`同意成功`)
          this.$emit('uploadSuccess')
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handelToDeatil(type, id) {
      if (type === 1) {
        this.$router.push({
          path: `/companyDetail/${id}`
        })
      } else {
        this.$router.push({
          path: `/teams/detail/${id}`
        })
      }
    },
    withdraw(info) {
      removeApply({
        applyId: info.id
      }).then(res => {
        this.init()
        this.$message.success(`已撤回`)
        console.log('res ', res)
      })
    },
    checkAll() {
      this.showAllApply = true
    },
    init() {
      const params = {
        projectId: this.projectId
      }
      checkTenderList(params)
        .then(res => {
          if (res.code === 200) {
            let that = this
            console.log('that.jurisdictionCode ', that.jurisdictionCode)
            this.list = res.data || []
          }
        })
        .catch(err => err)
    },
    handleSetBidDemand() {
      this.showDialog = true
    },
    uploadSuccess() {
      this.init()
      this.$emit('uploadAuth')
    },
    resetBudget(budget) {
      if (budget === '') return '未知'
      if (budget < 10000) return `${budget}元`
      return `${budget / 10000}万`
    }
  }
}
</script>

<style lang="scss" scoped>
.comp-info-box {
  margin: 40px 0;
  position: relative;
  .comp-info-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .comp-info-point {
      width: 9px;
      height: 9px;
      background: #5ad3cd;
      margin: 10px 11px 10px 0;
    }
    .comp-info-title {
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }
  }
  .comp-info-basic-container {
    margin: 0 0 16px 0;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    display: flex;
    .comp-info-basic {
      color: #999999;
      margin-right: 10px;
    }
    .comp-info-basic-info {
      color: #333333;
    }
  }
  ul li {
    height: 30px;
    margin-bottom: 20px;
    vertical-align: top;
    img {
      height: 30px;
      width: 30px;
      display: inline-block;
      margin-right: 20px;
      vertical-align: top;
    }
    .name {
      width: 150px;
      display: inline-block;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 30px;
      margin-right: 42px;
      vertical-align: top;
      cursor: pointer;
    }
    .prict {
      width: 150px;
      margin-right: 38px;
      display: inline-block;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 30px;
      vertical-align: top;
    }
    .desc {
      display: inline-block;
      vertical-align: top;

      span {
        height: 24px;
        display: inline-block;
        padding: 0 9px;
        margin-right: 10px;
        background: #f1f1f1;
        border-radius: 2px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 24px;
      }
    }
    .btn-list {
      float: right;
      margin-top: 3px;
      vertical-align: top;
      .consent {
        width: 60px;
        height: 24px;
        background: #5ad3cd;
        border-radius: 12px;
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 24px;
        text-align: center;
        margin-right: 10px;
        float: left;
        cursor: pointer;
      }
      .withdraw {
        width: 60px;
        height: 24px;
        border-radius: 12px;
        border: 1px solid #ff724c;
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ff724c;
        line-height: 24px;
        text-align: center;
        float: left;
        cursor: pointer;
      }
    }
  }
  button {
    background: #5ad3cd;
    padding: 3px 26px;
    border-radius: 15px;
  }
  .check-all {
    position: absolute;
    width: 80px;
    height: 24px;
    background: #f1f1f1;
    border-radius: 2px;
    right: 0;
    bottom: 0;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4a90e2;
    line-height: 24px;
    text-align: center;
    padding: 0;
    border: 0;
  }
  .apply {
    width: 200px;
    height: 28px;
    background: #ffaa30;
    border-radius: 14px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 28px;
    text-align: center;
    padding: 0;
    border: 0;
    margin: 0 auto;
    display: block;
  }
}
</style>
